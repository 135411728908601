import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from '../actions/alert';
import * as CommonMessages from '../constants/common';
import { showSuccessMessage, showFailureMessage } from './toast';

const setUserDevices = (list: any[]) => {
  return {
    type: ActionTypes.SET_DEVICES,
    payload: {
      list
    }
  }
}

export function getUserDevices() {
  return (dispatch: any, getState: any) => {
    const { auth: { user }} = getState();
    return ApiService.get(`/user/${user.email}/device/all`)
      .then(response => {
        if(response.status === 200) {
          const { data, success } = response.data;
          if(success && data) {
            const devices = data.devices || [];
            dispatch(setUserDevices(devices));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function logoutDevice(device: any, cb?: any) {
  return (dispatch: any, getState: any) => {
    return ApiService.put(`/user/logout/device/${device.deviceId}`)
      .then(response => {
        const { success, message } = response.data;
        const erroMessage = message || CommonMessages.COMMON_FAILURE;

        if(response.status !== 200 || !success) {
          return showFailureMessage(erroMessage)
        }
        
        dispatch(getUserDevices())
        showSuccessMessage(message);
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function logoutAllDevices(device: any) {
  return (dispatch: any, getState: any) => {
    const { auth: { user }} = getState();
    return ApiService.put('/user/logout/device/all', device)
      .then(response => {
        const { success, message } = response.data;
        const erroMessage = message || CommonMessages.COMMON_FAILURE;

        if(response.status !== 200 || !success) {
          return showFailureMessage(erroMessage)
        }
        
        dispatch(getUserDevices())
        showSuccessMessage(message);
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }  
}