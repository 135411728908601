import ActionTypes from '../constants/actionTypes';
import * as CommonMessages from '../constants/common';
import { ApiService, AuthService } from '../services';
import { getBrowserInfo, getOrCreateDeviceIndentity } from '../helpers';
import { showSuccessMessage, showFailureMessage } from './toast';
import { setAccountActivationCookie, removeAccountActivationCookie } from '../helpers/common';

export const changeAuthField = (key: string, value: string) => {
  return {
    type: ActionTypes.AUTH_FIELD_UPDATE,
    payload: {
      [key]: value
    }
  }
}

export const setErrors = (errors: any) => {
  return {
    type: ActionTypes.SET_UNSET_AUTH_ERRORS,
    payload: {
      errors
    }
  }
}

export const requestProcessing = (value: boolean) => {
  return {
    type: ActionTypes.API_REQUEST,
    payload: {
      inProgress: value
    }
  }
}

export const handleApiStatus = (inProgress: boolean, success: boolean) => {
  return {
    type: ActionTypes.API_REQUEST,
    payload: {
      inProgress,
      success
    }
  }
}

export const setCurrentUser = (user: any, device: any) => {
  return {
    type: ActionTypes.SET_USER,
    payload: {
      user,
      device,
      isAuthenticated: true
    }
  }
}

export const logoutUser = () => {
  return {
    type: ActionTypes.LOGOUT,
  }
}

export const resetAuth = () => {
  return {
    type: ActionTypes.RESET_AUTH
  }
}

export const facebookLoginInitiated = () => {
  return {
    type: ActionTypes.FACEBOOK_AUTH_INITIATED
  }
}

export const facebookLoginSuccess = (status: boolean) => {
  return {
    type: ActionTypes.FACEBOOK_AUTH_SUCCESS,
    payload: {
      fbRequestSuccess: status
    }
  }
}

export function onRegisterationSubmitForm(payload: any, successRedirectCb: any) {
  return (dispatch: any, getState: any) => {  
    const deviceDetails = {}; 

    if(window.navigator) {
      const { userAgent, platform } = window.navigator
      deviceDetails['os'] = platform || "unknown";
      deviceDetails['userAgent'] = userAgent;
      deviceDetails['browser'] = getBrowserInfo(userAgent);
      deviceDetails['deviceKey'] = getOrCreateDeviceIndentity();
    }

    const userPayload = {
      ...payload,
      ...deviceDetails,
      // TODO: not sure about below values
      role: "member", 
      type: "todo"
    };

    dispatch(handleApiStatus(true, false));

    AuthService.loginOrRegister("/user/create", userPayload)
      .then(response => {
        const { data , message } = (response.data || {});
        const errorMessage = message || CommonMessages.COMMON_FAILURE;

        // for failed response
        if(![200, 201].includes(response.status)) {
          dispatch(handleApiStatus(false, false));
          return dispatch(setErrors({ "Error:" :  errorMessage }));
        }

        // for sucessful response
        dispatch(handleApiStatus(false, true));

        const { user }  = data;        

        // user create failure
        if(!user) {
          return dispatch(setErrors({ "Error:" : errorMessage }));
        }

        if(!user.emailVerified) {
          const { email, password } = payload;
          // setAccountActivationCookie
          // setAccountActivationCookie(JSON.stringify({ email: 'ravi.react.developer@gmail.com', password: 'ravi@123' }));
          setAccountActivationCookie(JSON.stringify({ email, password }));
          return successRedirectCb(user.id);
        }

        // user found
        //dispatch(setCurrentUser( user, device ));
      })
      .catch(error => {
        dispatch(handleApiStatus(false, false));
        dispatch(setErrors({ "Error:" : `Something went wrong.. ${error.message}` }));
      })
  }
}

export function onLoginSubmitForm(payload: any, inactiveAccountRedirection?: any) {
  return (dispatch: any, getState: any) => {

    const deviceDetails = {}; 

    if(window.navigator) {
      const { userAgent, platform } = window.navigator
      deviceDetails['os'] = platform || "unknown";
      deviceDetails['userAgent'] = userAgent;
      deviceDetails['browser'] = getBrowserInfo(userAgent);
      deviceDetails['deviceKey'] = getOrCreateDeviceIndentity();
    }

    const userPayload = {
      ...payload,
      ...deviceDetails,
      // TODO: not sure about below values
      role: "member", 
      type: "todo"
    };

    dispatch(handleApiStatus(true, false));

    return AuthService.loginOrRegister("/user/login", userPayload )
      .then(response => {
        const { data,  message } = (response.data || {});
        const errorMessage = message || CommonMessages.COMMON_FAILURE;
        // failure response
        if(response.status !== 200) {
          dispatch(handleApiStatus(false, false));
          return dispatch(setErrors({ "Error:" : errorMessage }));       
        }

        dispatch(handleApiStatus(false, true));

        const { user, type, deviceKey, deviceId } = data;
        const device = {
          deviceId,
          deviceKey,
          type
        }

        // wrong credentials
        if(!user) {
          return dispatch(setErrors({ "Server Error:" : errorMessage}))
        }

        if(!user.emailVerified) {
          const { email, password } = payload;
          // setAccountActivationCookie
          // setAccountActivationCookie(JSON.stringify({ email: 'ravi.react.developer@gmail.com', password: 'ravi@123' }));
          setAccountActivationCookie(JSON.stringify({ email, password }));
          showFailureMessage(CommonMessages.ACTIVATION_REQUIRED)
          return inactiveAccountRedirection(user.id);
        }

        // remove account activation cookie
        removeAccountActivationCookie()
        // user found
        dispatch(setCurrentUser( user, device ));
      })
      .catch(error => {
        dispatch(handleApiStatus(false, false));
        dispatch(setErrors({ "Error:" : `Something went wrong.. ${error.message}` }))
      })
  }
}

export function resendAccountActivationLink(id: any) {
  return (dispatch: any, getState: any) => {
    dispatch(handleApiStatus(true, false));

    ApiService.post(`/user/${id}/resendVerificationLink`)
      .then(response => {
        const { message, success } = response.data;
        const errorMessage = message || CommonMessages.COMMON_FAILURE;

        if(response.status === 200) {
          dispatch(handleApiStatus(false, true));
          
          if(!success) {
            return showFailureMessage(errorMessage);
          }

          showSuccessMessage(CommonMessages.ACCOUNT_ACTIVATION);
        } else {
          dispatch(handleApiStatus(false, false));
          showFailureMessage(CommonMessages.COMMON_FAILURE)
        }
      })
      .catch(error => {
        const errorMessage = error.message || CommonMessages.COMMON_FAILURE;        
        dispatch(handleApiStatus(false, false));
        showFailureMessage(errorMessage);
      });
  }
}

export function onForgotPasswordSubmitForm(payload: any, successCb?: () => void) {
  return (dispatch: any, getState: any) => {
    dispatch(handleApiStatus(true, false));

    ApiService.put(`/user/${payload.email}/forgotPassword`,  payload)
      .then(response => {
        const { message, success } = response.data;
        const errorMessage = message || CommonMessages.COMMON_FAILURE;

        if(response.status === 200) {
          dispatch(handleApiStatus(false, true));
          
          if(!success) {
            return showFailureMessage(errorMessage);
          }

          showSuccessMessage(CommonMessages.FORGOT_PASSWORD);

          if(successCb) {
            successCb();
          }
        } else {
          dispatch(handleApiStatus(false, false));
          showFailureMessage(CommonMessages.COMMON_FAILURE)
        }
      })
      .catch(error => {
        const errorMessage = error.message || CommonMessages.COMMON_FAILURE;        
        dispatch(handleApiStatus(false, false));
        showFailureMessage(errorMessage);
      });
  }
}

export function onResetPasswordSubmitForm(resetPasswordRequest: any) {
  return (dispatch: any, getState: any) => {
    dispatch(handleApiStatus(true, false));

    ApiService.post(`/user/resetPassword`,
      resetPasswordRequest
    ).then(response => {
      const { message, success } = response.data;
      const errorMessage = message || CommonMessages.COMMON_FAILURE;

      if(response.status === 200) {
        dispatch(handleApiStatus(false, true));

        if(!success) {
          return showFailureMessage(errorMessage);
        }

        showSuccessMessage(message);
      } else {
        dispatch(handleApiStatus(false, false));
        showFailureMessage(CommonMessages.COMMON_FAILURE)
      }
    })
    .catch(error => {
      dispatch(handleApiStatus(false, false));
      dispatch(setErrors({ "Error:" : `Something went wrong.. ${error.message}` }))
    });
  }
}

// create user if not exist on server side
// OR returns user if already exists
export function getUserFromFbDetails(payload: any) {}


