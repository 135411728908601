import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  list: []
}

export default (state = defaultState, action: IAction) => {
  const payload = action.payload || {};

  switch (action.type) {
    case Action.SET_ARTICLE_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      };
    case Action.UNSET_ARTICLE_CATEGORIES:
      return {
        ...defaultState
      }
    case Action.SET_UNSET_ARTICLE_CATEGORIES_ERRORS:
      return {
        ...state,
        errors: payload.errors
      }
    default:
      return state;
  }
};