import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Widget from '../components/widget';
import {
  getPublisherArticleCategories,
  toggleActiveStateForCategory,
  unsetArticleCategories,
  updateArticleCategoryPrice,
  switchMonetizeType,
} from '../actions/articleCategory';
import { getPublisher } from '../actions/publisher';
import { Getter } from '@devexpress/dx-react-core';
import {
  EditingState,
  SortingState,
  IntegratedSorting,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  defaultColumnWidthsArtCat as defaultColumnWidths,
  gridColumnsMappingsArtCat as gridColumnsMappings,
  integratedSortingColumnExtensions
} from '../constants/columnDefs';

class ArticleCategory extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { showEditModal: false, rows: [], columns: [], editableColumns: [] };
    this.onRowChanges = this.onRowChanges.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.buildColumns();
    this.buildEditableColumns();
    this.getPublisherAndArticleCategories(id);
  }

  componentWillReceiveProps(nextProps: any) {
    const { id } = this.props.match.params;
    const nextId = nextProps.match.params.id;
    if (id !== nextId) {
      this.getPublisherAndArticleCategories(nextId);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unsetArticleCategories());
  }

  getPublisherAndArticleCategories(id) {
    this.props.dispatch(getPublisher(id));
    this.props
      .dispatch(getPublisherArticleCategories(id))
      .then(() => {
        this.buildRows();
      })
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({
        name: column.name,
        title: column.title
      })
      )
    this.setState({ columns: gridColumns });
  }

  buildRows() {
    const { list } = this.props;
    let rows = list.map((articleCategory, i) => {
      const row = {
        index: i + 1,
        id: articleCategory.id,
        publisherId: articleCategory.publisherId,
        category: articleCategory.category,
        price: articleCategory.price,
        price1: articleCategory.tipJarPriceOptions?.prices[0] ?? 0,
        price2: articleCategory.tipJarPriceOptions?.prices[1] ?? 0,
        price3: articleCategory.tipJarPriceOptions?.prices[2] ?? 0,
        monetizeType : articleCategory?.monetizeType ?? 'paywall', 
      };
      Object.keys(row).forEach(key => row[key] = row[key] || '');
      return row;
    });

    this.setState({ rows });
  }

  buildEditableColumns() {
    const editableColumns = gridColumnsMappings.map(column => {
      return { columnName: column.name, editingEnabled: !!column.editable }
    });
    this.setState({ editableColumns });
  }

  getRowId(row) {
    return row.index;
  }

  onRowChanges({ added, changed, deleted }: any) {
    let changedRows;

    if (changed) {
      const { rows } = this.state;
      const { list } = this.props;
      const rowIndex: number = parseInt(Object.keys(changed)[0]);
      const changedValues = changed[rowIndex];

      // update on server
      this.props.dispatch(updateArticleCategoryPrice({
        ...list[rowIndex - 1],
        ...changedValues
      }, () => {
        // build upadted grid data
        changedRows = [...rows]
        changedRows[rowIndex - 1] = {
          ...changedRows[rowIndex - 1],
          ...changedValues
        }

        // update component state for grid
        this.setState({ rows: changedRows });
      })
      );
    }
  }

  toggleActiveStateForCategory(categoryId: number, publisherId: number, activeState: boolean) {
    this.props.dispatch(toggleActiveStateForCategory(categoryId, publisherId, activeState));
  }

  toggleMonetizeType(categoryId: number, publisherId: number, monetizeType: string) {
    this.props.dispatch(switchMonetizeType(categoryId, publisherId, monetizeType)).then(() => this.buildRows());
  }

  render() {

    const activateAllArticles = ({ value, onValueChange, ...props }) => {
      const { category, id, publisherId } = props.row;
      return (
        <button
          className="btn btn-success btn-sm pull-center"
          title={category}
          onClick={e => { this.toggleActiveStateForCategory(id, publisherId, true) }}
        >
          <span>
            <i className="fa fa-check mr-2"></i>Activate All Content
          </span>
        </button>
      )
    }

    const deactivateAllArticles = ({ value, onValueChange, ...props }) => {
      const { category, id, publisherId } = props.row;
      return (
        <button
          className="btn btn-danger btn-sm pull-center"
          title={category}
          onClick={e => { this.toggleActiveStateForCategory(id, publisherId, false) }}
        >
          <span>
            <i className="fa fa-times mr-2"></i>Deactivate All Content
          </span>
        </button>
      )
    }

    const switchMonetizeType = ({ value, onValueChange, ...props }) => {
      const { category, id, publisherId, monetizeType } = props.row;
      const newMonetizeType = monetizeType === 'paywall' ? 'tipjar' : 'paywall';
      return (
        <button
          className="btn btn-success btn-sm pull-center"
          title={category}
          onClick={e => { this.toggleMonetizeType(id, publisherId, newMonetizeType) }}
        >
          <span>
            <i className="fa fa-sync mr-2"></i>Switch to - {newMonetizeType}
          </span>
        </button>
      )
    }

    const ActivateAllArticlesProvider = props => (
      <DataTypeProvider formatterComponent={activateAllArticles} {...props} />
    );

    const DeactivateAllArticlesProvider = props => (
      <DataTypeProvider formatterComponent={deactivateAllArticles} {...props} />
    );

    const SwitchMonetizeTypeArticlesProvider = props => (
      <DataTypeProvider formatterComponent={switchMonetizeType} {...props} />
    );

    let { publisher } = this.props;
    const { rows, columns, editableColumns } = this.state;

    return (
      <Widget>
        <div className="table-responsive container-fluid">
          <h5>Content Categories { publisher && <span>(Publisher: {publisher.name} | Currency: {publisher.bookCurrency.code})</span>} </h5>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={this.getRowId}
          >
            <EditingState
              onCommitChanges={this.onRowChanges}
              columnExtensions={editableColumns}
            />
            <SortingState
              defaultSorting={[{ columnName: 'category', direction: 'asc' }]}
            />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              showEditCommand
            />
            <ActivateAllArticlesProvider for={["activateAll"]} />
            <DeactivateAllArticlesProvider for={["deactivateAll"]} />
            <SwitchMonetizeTypeArticlesProvider for={["monetizeType"]} />
            <TableEditRow />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const result = [
                  ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                  { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 140 }
                ];
                return result;
              }}
            />
          </Grid>
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (appState: any) => {
  return {
    publisher: appState.publishers.selectedPublisher,
    list: appState.articlecategories.list,
    errors: appState.articlecategories.errors,
    user: appState.auth.user
  }
};

export default connect(mapStateToProps)(ArticleCategory);