import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from '../actions/alert';
import * as CommonMessages from '../constants/common';

function setPublisherWallets(list: any[]) {
  return {
    type: ActionTypes.SET_WALLETS,
    payload: {
      list
    }
  }  
}

export function getPublisherWallets(publisherId: number) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${publisherId}/wallet`)
      .then(response => {
        const { data, success} = response.data;
        if(response.status === 200 && success) {
          if(data) {
            dispatch(setPublisherWallets(data.publisherWallets));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`));
      });
  }  
}