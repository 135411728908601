import { toast } from 'react-toastify';

export function showSuccessMessage(message: string) {
  const toastMessage = message || "Successful..."
  toast.success(toastMessage, {
    position: "top-right",
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}

export function showFailureMessage(message: string) {
  const toastMessage = message || "Failed..."
  toast.error(toastMessage, {
    position: "top-right",
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}