import React from 'react';
import { connect } from 'react-redux';
import { updateProfile } from '../actions/profile';
import ListErrors from '../components/listErrors';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup
} from 'reactstrap';
import Widget from '../components/widget';
import s from '../styles/profile.module.scss';
import { getUserDevices } from '../actions/device';
import { getDeviceIndetity } from '../helpers';
import { getPublishers } from '../actions/publisher';
import Devices from '../components/devices';
import UpdatePassword1 from '../components/updatePassword';

class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { updatedName: props.user.name, errors: null };
    this.updateName = this.updateName.bind(this);
    this.resetUserInfo = this.resetUserInfo.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getUserDevices());
    this.props.dispatch(getPublishers());
  }

  updateName(e: any) {
    this.setState({ updatedName: e.target.value });
  }

  stateChanged() {
    const { updatedName } = this.state;
    return updatedName !== this.props.user.name;
  }

  resetUserInfo(e: any) {
    e.preventDefault();
    this.setState({ updatedName: this.props.user.name, errors: null });
  }

  updateUserInfo() {
    const { updatedName } = this.state;
    this.setState({ errors: null });

    if (this.stateChanged()) {
      let payload = {};
      const errors: any = {};

      if (!updatedName) {
        errors['Name'] = 'is required';
      }

      if (Object.keys(errors).length) {
        return this.setState({ errors });
      }

      if (updatedName && updatedName !== this.props.name) {
        payload['name'] = updatedName.trim();
      }

      this.props.dispatch(updateProfile(payload));
    }
  }

  render() {
    const { user, devices } = this.props;
    const { updatedName, errors } = this.state;
    const currentDeviceId = getDeviceIndetity();

    return (
      <div className={s.root}>
        <Row>
          <Col sm={6}>
            <Widget
              title={
                <h5 style={{ marginBottom: '0px', color: '#21ce99' }}>
                  Profile
                </h5>
              }
            >
              <Form className='mt-sm-2'>
                {errors && <ListErrors errors={errors} />}
                <FormGroup>
                  <Label>Email: {user.email}</Label>
                  <div>
                    <Label for='input-name' style={{ color: '#21ce99' }}>
                      Role: {user.role}
                    </Label>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for='input-name'>Name:</Label>
                  <Input
                    bsSize='lg'
                    style={{ display: 'inline', marginLeft: '5px' }}
                    type='text'
                    name='name'
                    className='col-sm-8'
                    id='input-name'
                    value={updatedName}
                    onChange={this.updateName}
                  />
                </FormGroup>

                <div className='d-flex justify-content-between align-items-center'>
                  <ButtonGroup className='pull-right'>
                    <Button
                      className='ml-sm'
                      color='default'
                      disabled={!this.stateChanged()}
                      onClick={this.resetUserInfo}
                    >
                      Cancel
                    </Button>
                    <Button
                      color='success'
                      disabled={!this.stateChanged()}
                      onClick={this.updateUserInfo}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            </Widget>
            {/* Profile ends */}
            <Widget>
              <UpdatePassword1
                user={this.props.user}
                dispatch={this.props.dispatch}
              />
            </Widget>
          </Col>
          <Col sm={6}>
            <Widget>
              <Devices
                devices={devices}
                currentDeviceId={currentDeviceId}
                dispatch={this.props.dispatch}
              />
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    user: appState.auth.user,
    devices: appState.devices.list,
    isAuthenticated: appState.auth.isAuthenticated
  };
};

export default connect(mapStateToProps)(Profile);
