const configs = {
  dev: {
    ApiBaseUrl: 'https://api.hounds.fewcents.co/v1',
    CookieDomain: (window.location.host.includes('localhost') && 'localhost') || 'publisher.hounds.fewcents.co',
    Domain: 'https://publisher.hounds.fewcents.co',
    SDKUrl: 'https://dev.fewcents.co',
    WebsiteTitle: 'Fewcents Dashboard - Dev'
  },
  demo: {
    ApiBaseUrl: 'https://api.demo.fewcents.co/v1',
    CookieDomain: 'publisher.demo.fewcents.co',
    Domain: 'https://publisher.demo.fewcents.co',
    SDKUrl: 'https://staging.fewcents.co',
    WebsiteTitle: 'Fewcents Dashboard - Staging'
  },
  prod: {
    ApiBaseUrl: 'https://api-publisher.fewcents.co/v1',
    CookieDomain: 'publisher.fewcents.co',
    Domain: 'https://publisher.fewcents.co',
    SDKUrl: 'https://paywall.fewcents.co',
    WebsiteTitle: 'Fewcents Dashboard'
  }
};
/*export const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'demo' ? demo : dev;*/

export const config = configs[String(process.env.REACT_APP_STAGE).trim() || 'dev'];

export const Domain = config.Domain;
export const SDKUrl = config.SDKUrl;
export const ApiBaseUrl = config.ApiBaseUrl;
export const CookieDomain = config.CookieDomain;
export const WebsiteTitle = config.WebsiteTitle;
