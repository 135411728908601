import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LinksGroup from './linksGroup';
import s from '../styles/sidebar.module.scss';

const Sidebar = (props: any) => {
  const { publisherList } = props;

  return (
    <nav className={s.root}>
      <header className={s.logo}>
        <h2 className="text-center" style={{ color: "white", fontWeight: "bold"}}>
            Few¢ents
        </h2>
      </header>
      <div style={{height: '80%', overflowY: 'auto'}}>
        <ul className={s.nav}>
          <LinksGroup
            header="Profile"
            headerLink="/app/profile"
            glyph="fa-user"
          />

          <LinksGroup
            header="Publishers"
            headerLink="/app/publishers"
            glyph="fa-newspaper"
          />

          {
            publisherList.map((p, index) => {
              return <LinksGroup
                header={p.domain}
                key={`link_group_${index}`}
                history={props.history}
                headerLink={`/app/publishers/${p.id}`}
                detailsUrl={`/app/publishers/${p.id}`}
                childrenLinks={[
                  {
                    name: 'Content',
                    link: `/app/publishers/${p.id}/articles`,
                    glyph: 'fa-pen-alt',
                  },
                  {
                    name: 'Content Categories',
                    link: `/app/publishers/${p.id}/artcileCategories`,
                    glyph: 'fa-list-alt',
                  },
                  {
                    name: 'Balances',
                    link: `/app/publishers/${p.id}/wallets`,
                    glyph: 'fa-wallet',
                  },
                  {
                    name: 'History',
                    link: `/app/publishers/${p.id}/activities`,
                    glyph: 'fa-book-reader',
                  },
                  {
                    name: 'Statistics-Paywall',
                    link: `/app/publishers/${p.id}/rpm`,
                    glyph: 'fa-chart-bar',
                  },
                  {
                    name: 'Statistics-Tipjar',
                    link: `/app/publishers/${p.id}/rpm-tipjar`,
                    glyph: 'fa-chart-bar',
                  }
                ]}
              />
            })
          }
        </ul>
      </div>
    </nav>
  );
}

function mapStateToProps(store) {
  return {
    sidebarOpened: true,
    sidebarStatic: false,
    publisherList: store.publishers.list,
    selectedPublisher: store.publishers.selectedPublisher
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
