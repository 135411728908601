import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'reactstrap';
import Widget from '../components/widget';
import { onResetPasswordSubmitForm, resetAuth } from '../actions/auth';
import ListErrors from '../components/listErrors';
import style from '../styles/login.module.scss';
import { passwordValidator } from '../validations/common';
import { TextField, FormGroup, Button } from '@material-ui/core';
import { WebsiteTitle } from '../config';

class ResetPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      newPassword: '',
      passwordConfirmation: '',
      resetCode: '',
      errors: {}
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(resetAuth());
  }

  redirectToLoginPage() {
    this.props.history.push('/login');
  }

  handleFieldUpdate(e: any) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFormSubmit(e: any) {
    let errors: any = {};
    e.preventDefault();
    this.setState({ errors });

    const { email, newPassword, passwordConfirmation, resetCode } = this.state;

    if (!email) {
      errors['Email:'] = 'can not be empty';
    }

    if (!newPassword) {
      errors['Password:'] = 'can not be empty';
    }

    if (!passwordConfirmation) {
      errors['Password Confirmation:'] = 'can not be empty';
    }

    if (!resetCode) {
      errors['Reset Code:'] = 'can not be empty';
    }

    if (newPassword) {
      const passwordError = passwordValidator(newPassword);
      errors = { ...errors, ...passwordError };
    }

    if (newPassword && passwordConfirmation && newPassword !== passwordConfirmation) {
      errors['Password confirmation'] = 'did not match';
    }

    if (Object.keys(errors).length > 0) {
      return this.setState({ errors });
    }

    this.props.dispatch(
      onResetPasswordSubmitForm({
        email,
        newPassword,
        resetCode
      })
    );
    this.redirectToLoginPage();
  }

  render() {
    const stateErrors = this.state.errors;
    const propsErrors = this.props.errors;
    const errors = Object.keys(stateErrors).length ? stateErrors : propsErrors;

    return (
      <div className={style.root}>
        <Row>
          <Col>
            <Widget className={style.widget}>
              <h2 className={style.title}>{WebsiteTitle}</h2>
              <h4 style={{ textAlign: 'left' }}>
                <strong>Reset Password</strong>
              </h4>
              <div style={{ textAlign: 'left', color: '#666' }}>
                <span>Check your email for password reset code</span>
              </div>
              <Form>
                {errors && !this.props.inProgress && <ListErrors errors={errors} />}
                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    type="email"
                    required
                    name="email"
                    label="Email"
                    onBlur={this.handleFieldUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    type="password"
                    required
                    name="newPassword"
                    label="New Password"
                    onBlur={this.handleFieldUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    type="password"
                    required
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    onBlur={this.handleFieldUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    type="resetCode"
                    required
                    name="resetCode"
                    label="Reset Code"
                    onBlur={this.handleFieldUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    type="submit"
                    style={{ marginTop: '10px', backgroundColor: '#1ab394' }}
                    variant="contained"
                    onClick={this.handleFormSubmit}
                    disabled={this.props.inProgress}
                  >
                    {this.props.inProgress ? 'Sending...' : 'Reset'}
                  </Button>
                </FormGroup>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Link to="/login" className="fs-sm" style={{ color: '#21ce99' }}>
                    Existing User
                  </Link>
                  <Link to="/register" className="fs-sm" style={{ color: '#21ce99' }}>
                    New User
                  </Link>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    ...appState.auth
  };
};

export default connect(mapStateToProps)(ResetPassword);
