import React, {Component} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { Route } from 'react-router';
import s from '../styles/linksGroup.module.scss';

class LinksGroup extends Component<any, any> {
  /* eslint-disable */
  static propTypes = {
    header: PropTypes.node.isRequired,
    headerLink: PropTypes.string,
    childrenLinks: PropTypes.array,
    glyph: PropTypes.string,
    className: PropTypes.string,
  };
  /* eslint-enable */

  static defaultProps = {
    headerLink: null,
    childrenLinks: null,
    className: '',
    glyph: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.handleParentMenuClick = this.handleParentMenuClick.bind(this);
  }

  handleParentMenuClick() {
    const { isOpen } = this.state;
    if(!isOpen) {
      this.props.history.push(this.props.detailsUrl)
    }
    this.setState({isOpen: !isOpen})
  }

  render() {
    const { className, childrenLinks, headerLink, header, glyph } = this.props;
    const { isOpen } = this.state;
    if (!childrenLinks) {
      return (
        <li className={cx(s.headerLink, className)}>
          <NavLink
            to={headerLink}
            activeClassName={s.headerLinkActive}
            exact
          >
            <div>
              <span className="ml-2">
                {glyph && <i className={`fas ${glyph}`} style={{ marginRight: "8px" }}aria-hidden="true"/>}
                {header}
              </span>
            </div>
          </NavLink>
        </li>
      );
    }
    /* eslint-disable */
    return (
      <Route
        path={headerLink}
        children={({match}) => {
          return (
            <li className={cx(s.headerLink, className)}>
              <a
                className={cx({[s.headerLinkActive]: !!match && match.url.indexOf(headerLink) !== -1 })}
                onClick={this.handleParentMenuClick}
              >
                <div>
                  {glyph && <i className={`fa ${glyph} mr-sm`} style={{color: 'black'}} aria-hidden="true"></i>}
                  <span>{header}</span>
                </div>
                <b className={cx('fas fa-angle-down arrow', s.arrow, {[s.arrowActive]: isOpen})} />
              </a>
              {/* eslint-enable */}
              <Collapse className={s.panel} isOpen={isOpen}>
                <ul>
                  {childrenLinks &&
                   childrenLinks.map((child, _) => (
                    <li key={_}>
                      <NavLink
                        to={child.link}
                        exact
                        onClick={() =>
                          this.setState({
                            isOpen: true,
                          })
                        }
                        activeClassName={s.headerLinkActive}
                      >
                        <span>
                          {child.glyph && <i className={`fas ${child.glyph} mr-sm`} style={{marginRight: '8px'}} aria-hidden="true"></i>}
                          {child.name}
                        </span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </li>
          );
        }}
      />
    );
  }
}

export default LinksGroup;
