import ActionTypes from '../constants/actionTypes';
import * as CommonMessages from '../constants/common';
import { ApiService } from '../services';
import { showSuccessMessage, showFailureMessage } from './toast';

export const profileUpdate = (payload: any) => {
  return {
    type: ActionTypes.PROFILE_UPDATE,
    payload
  };
};

export function updateProfile(payload: any) {
  return (dispatch: any, getState: any) => {
    const {
      auth: { user }
    } = getState();

    ApiService.put(`/user/${user.email}/edit`, payload)
      .then(response => {
        const { data, success, message } = response.data || {};

        if (response.status === 200 && success) {
          showSuccessMessage('Updated successfully');
          dispatch(profileUpdate(data));
        } else {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(errorMessage);
        }
      })
      .catch(error => {
        showFailureMessage(`Error: ${error.message}`);
      });
  };
}

export function updatePassword(payload: any) {
  return (dispatch: any, getState: any) => {
    const {
      auth: { user }
    } = getState();

    return ApiService.put('/user/updatePassword', {
      ...payload,
      email: user.email
    })
      .then(response => {
        const { data, success, message } = response.data;

        if (response.status === 200 && success) {
          dispatch(profileUpdate(data));
          return { success: true };
        } else {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(errorMessage);
          return { success: false };
        }
      })
      .catch(error => {
        showFailureMessage(`Error: ${error.message}`);
      });
  };
}
