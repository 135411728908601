import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import devices from './device';
import publishers from './publisher';
import articles from './article';
import wallets from './wallets';
import activities from './activities';
import articlecategories from './articlecategories';
import rpm from './rpm';
import rpmtipjar from './rpmtipjar';

export default combineReducers({
  auth,
  alert,
  devices,
  publishers,
  articles,
  wallets,
  activities,
  articlecategories,
  rpm,
  rpmtipjar,
});
