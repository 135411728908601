enum Action {
  AUTH_FIELD_UPDATE = 'AUTH_FIELD_UPDATE',
  RESET_AUTH = 'RESET_AUTH',
  SET_UNSET_AUTH_ERRORS = 'SET_UNSET_AUTH_ERRORS',
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
  PROFILE_UPDATE = 'PROFILE_UPDATE',
  API_REQUEST = 'API_REQUEST',
  SET_ALERT_TEXT = 'SET_ALERT_TEXT',
  CLEAR_ALERT_TEXT = 'CLEAR_ALERT_TEXT',
  FACEBOOK_AUTH_INITIATED = 'FACEBOOK_AUTH_INITIATED',
  FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS',
  GOOGLE_AUTH_INITIATED = 'GOOGLE_AUTH_INITIATED',
  GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS',
  SET_DEVICES = 'SET_DEVICES',
  SET_PUBLISHERS = 'SET_PUBLISHERS',
  SET_SELECTED_PUBLISHER = 'SET_SELECTED_PUBLISHER',
  SET_ARTICLES = 'SET_ARTICLES',
  UNSET_ARTICLES = 'UNSET_ARTICLES',
  SET_UNSET_PUBLISHER_ERRORS = 'SET_UNSET_PUBLISHER_ERRORS',
  PUBLISHER_API_PROCESSING = 'PUBLISHER_API_PROCESSING',
  SET_UNSET_ARTICLE_ERRORS = 'SET_UNSET_ARTICLE_ERRORS',
  SET_WALLETS = 'SET_WALLETS',
  SET_UNSET_WALLET_ERRORS = 'SET_UNSET_WALLET_ERRORS',
  SET_LEDGERS = 'SET_LEDGERS',
  SET_FBIDS = 'SET_FBIDS',
  SET_CURRENCIES = 'SET_CURRENCIES',
  RESET_ACTIVITIES = 'RESET_ACTIVITIES',
  SET_ARTICLE_CATEGORIES = 'SET_ARTICLE_CATEGORIES',
  UNSET_ARTICLE_CATEGORIES = 'UNSET_ARTICLE_CATEGORIES',
  SET_UNSET_ARTICLE_CATEGORIES_ERRORS = 'SET_UNSET_ARTICLE_CATEGORIES_ERRORS',
  SET_DETAILED_STATS = 'SET_DETAILED_STATS',
  SET_AGGREGATED_STATS = 'SET_AGGREGATED_STATS',
  SET_STATS_COUNTRIES = 'SET_STATS_COUNTRIES',
  SET_STATS_CATEGORIES = 'SET_STATS_CATEGORIES',
  UNSET_STATISTICS = 'UNSET_STATISTICS',
  SET_UNSET_RPM_ERRORS = 'SET_UNSET_RPM_ERRORS',
  SET_AGGREGATED_STATS_TIPJAR = 'SET_AGGREGATED_STATS_TIPJAR',
  SET_STATS_TIPJAR_COUNTRIES = 'SET_STATS_TIPJAR_COUNTRIES',
  SET_STATS_TIPJAR_CATEGORIES = 'SET_STATS_TIPJAR_CATEGORIES',
  UNSET_STATISTICS_TIPJAR = 'UNSET_STATISTICS_TIPJAR',
  SET_UNSET_RPM_TIPJAR_ERRORS = 'SET_UNSET_RPM_TIPJAR_ERRORS',
  SET_HISTORY = 'SET_HISTORY',
}

export default Action;