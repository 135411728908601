import React from 'react';
import { updatePassword } from '../actions/profile';
import ListErrors from './listErrors';
import { Form, FormGroup, Label, Input, Button, ButtonGroup } from 'reactstrap';
import { browserHistory } from '../history';
import { AuthService } from '../services/authService';
import { logoutUser } from '../actions/auth';

export default class UpdatePassword1 extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      errors: null,
      processing: false
    };
    this.changeCurrentPassword = this.changeCurrentPassword.bind(this);
    this.changeNewPassword = this.changeNewPassword.bind(this);
    this.changeNewPasswordConfirmation = this.changeNewPasswordConfirmation.bind(
      this
    );
    this.reset = this.reset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeCurrentPassword(e: any) {
    this.setState({ currentPassword: e.target.value });
  }

  changeNewPassword(e: any) {
    this.setState({ newPassword: e.target.value });
  }

  changeNewPasswordConfirmation(e: any) {
    this.setState({ newPasswordConfirmation: e.target.value });
  }

  redirectToLoginPage() {
    browserHistory.push('/login');
  }

  stateChanged() {
    const {
      currentPassword,
      newPassword,
      newPasswordConfirmation
    } = this.state;
    return (
      currentPassword !== '' &&
      newPassword !== '' &&
      newPasswordConfirmation !== ''
    );
  }

  reset(e?: any) {
    if (e) e.preventDefault();
    this.setState({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      errors: null,
      processing: false
    });
  }

  handleSubmit() {
    const {
      currentPassword,
      newPassword,
      newPasswordConfirmation
    } = this.state;
    this.setState({ errors: null });
    if (this.stateChanged()) {
      const errors: any = {};
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

      const fieldMissing = field => field === '';
      if (
        [currentPassword, newPassword, newPasswordConfirmation].some(
          fieldMissing
        )
      ) {
        errors['All fields'] = 'are required';
      }

      // if(currentPassword == )

      if (newPassword && !passwordRegex.test(newPassword)) {
        errors['Password'] =
          'should be at least 8 characters long (At lease 1 alphabet, 1 numeric, 1 special character)';
      }

      if (newPassword !== newPasswordConfirmation) {
        errors['Password confirmation'] = 'did not match';
      }

      if (Object.keys(errors).length) {
        return this.setState({ errors });
      }
      this.setState({ processing: true });
      this.props
        .dispatch(
          updatePassword({
            email: this.props.user.email,
            password: currentPassword,
            updatedPassword: newPassword
          })
        )
        .then(response => {
          if (!response.success) {
            errors['Current Password'] = 'is incorrect';
            this.setState({ processing: false });
            return this.setState({ errors });
          } else {
            const email = this.props.user.email;
            AuthService
              .logOut(email)
              .catch(err => {
                console.log("TODO: Needs to logout device on server");
              })
              .finally(() => {
                this.props.dispatch(logoutUser());
                browserHistory.push('/login')
              })
          }
        });
    }
  }

  render() {
    const {
      currentPassword,
      newPassword,
      newPasswordConfirmation,
      errors,
      processing
    } = this.state;
    return (
      <div>
        <h5 style={{ marginBottom: '0px', color: '#21ce99' }}>
          Change Password
        </h5>

        <Form className='mt-sm-2'>
          {errors && <ListErrors errors={errors} />}
          <FormGroup>
            <Label for='input-password'>Current Password</Label>
            <Input
              bsSize='lg'
              type='password'
              name='currentPassword'
              id='input-currentPassword'
              placeholder='******'
              value={currentPassword}
              onChange={this.changeCurrentPassword}
            />
          </FormGroup>
          <FormGroup>
            <Label for='input-password'>New Password</Label>
            <Input
              bsSize='lg'
              type='password'
              name='newPassword'
              id='input-newPassword'
              placeholder='******'
              value={newPassword}
              onChange={this.changeNewPassword}
            />
          </FormGroup>
          <FormGroup>
            <Label for='input-newPassword'>New Password Confirmation</Label>
            <Input
              bsSize='lg'
              type='password'
              name='newPasswordConfirmation'
              id='input-newPasswordConfirmation'
              placeholder='******'
              value={newPasswordConfirmation}
              onChange={this.changeNewPasswordConfirmation}
            />
          </FormGroup>
          <div className='d-flex justify-content-between align-items-center'>
            <ButtonGroup className='pull-right'>
              <Button
                className='ml-sm'
                color='default'
                disabled={!this.stateChanged()}
                onClick={this.reset}
              >
                Cancel
              </Button>
              <Button
                color='success'
                disabled={!this.stateChanged() || processing}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      </div>
    );
  }
}
