import ActionTypes from '../constants/actionTypes';

export const setAlert = (text: string) => {
  return {
    type: ActionTypes.SET_ALERT_TEXT,
    payload: {
      text
    }
  }
}

export const clearAlert = () => {
  return {
    type: ActionTypes.CLEAR_ALERT_TEXT
  }
}
