import { CookieDomain } from "../config";
import { format, parse } from "date-fns";

const DEVICE_COOKIE_NAME = 'fewcentDeviceCookie';
const ACCOUNT_ACTIVATION = 'accountActivation';

export function getRandomString() {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const stringLength = 15;
  return Array.apply(null, Array(stringLength)).map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
}

export function getErrors(stateErrors, propsErrors) {
  if (Object.keys(stateErrors || propsErrors || {}).length > 0) {
    return stateErrors || propsErrors;
  }
  return null;
}

export function filterValue(val, checkNumber = false) {
  if (val === null || val === undefined) {
    return ""
  }

  if (val === true || val === false || (checkNumber && typeof val === "number")) {
    return val.toString();
  }

  return val;
}

export function numberWithCommasAndCurrency(value: number, currency: string) {
  const userLanguage = navigator.language
  if (value !== undefined && currency !== '')
    return new Intl.NumberFormat(userLanguage ? userLanguage : 'en-US', {
      style: 'currency', 
      currency: currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(value)
  else return value
}

export function numberWithCommas(value: number) {
  if (value !== undefined) {
    let result =  (value - Math.floor(value)) !== 0;
    if (result) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value.valueOf())
    } else {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value.valueOf())  
    }
  } 
  else return value
}

export function numberWithCommasAndFractions(value: number, fractionDigits: number) {
  if (value !== undefined) {
    let result =  (value - Math.floor(value)) !== 0;
    if (result) {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      }).format(value.valueOf())
    } else {
      return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value.valueOf())  
    }
  } 
  else return value
}

// expiryTimeMS: expiry in ms
export function setCookie(cname, cvalue, expiryTimeMS) {
  var d = new Date();
  d.setTime(d.getTime() + expiryTimeMS);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + CookieDomain;
}

export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(cname) {
  var expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = cname + "=" + "" + ";" + expires + "path=/;domain=" + CookieDomain;
}

export function getOrCreateDeviceIndentity() {
  const fewcentDeviceCookieUID = getCookie(DEVICE_COOKIE_NAME);
  if (fewcentDeviceCookieUID) {
    return fewcentDeviceCookieUID;
  }

  const uid = getRandomString();
  const exDays = 1825;
  const fiveYearsLongExpiry = exDays * 24 * 60 * 60 * 1000;
  setCookie(DEVICE_COOKIE_NAME, uid, fiveYearsLongExpiry); //lifetime 5 years
  return uid;
}

export function getDeviceIndetity() {
  return getCookie(DEVICE_COOKIE_NAME);
}

export function setAccountActivationCookie(value) {
  const expiry = 10 * 60 * 1000; // ten minutes expiry
  setCookie(ACCOUNT_ACTIVATION, value, expiry);
}

export function getAccountActivationCookie() {
  return getCookie(ACCOUNT_ACTIVATION);
}

export function removeAccountActivationCookie() {
  setCookie(ACCOUNT_ACTIVATION, "", -1);
}

export function getBrowserInfo(userAgentHeader: string) {
  let browser = '';
  // The order matters here, and this may report false positives for unlisted browsers.
  const userAgent = userAgentHeader.toLowerCase();

  if (userAgent.indexOf("firefox") > -1) {
    browser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (userAgent.indexOf("samsungbrowser") > -1) {
    browser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (userAgent.indexOf("opera") > -1 || userAgent.indexOf("opr") > -1) {
    browser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (userAgent.indexOf("trident") > -1) {
    browser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (userAgent.indexOf("edge") > -1) {
    browser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (userAgent.indexOf("chrome") > -1) {
    browser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (userAgent.indexOf("safari") > -1) {
    browser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    browser = "unknown";
  }
  return browser;
}

// function isMobileOrTablet(){
//   var check = false;
//   (function(a){
//       if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
//           check = true;
//   })(navigator.userAgent||navigator.vendor||(<any>window).opera);
//   return check;
// }


// function isMobile() {
//   var check = false;
//   (function(a){
//     if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
//       check = true;
//   })(navigator.userAgent||navigator.vendor||window.opera);
//   return check;
// };

export const filterDate = (val) => {
  if (val === null || val === undefined) {
    return val
  }
  return val.includes("Z") ? format(new Date(val), "dd/MM/yyyy, HH:mm") : format(new Date(`${val}Z`), "dd/MM/yyyy, HH:mm")
}

export const filterDateWithoutTime = (val) => {
  if (val === null || val === undefined) {
    return val
  }
  return val.includes("Z") ? format(new Date(val), "dd/MM/yyyy") : format(new Date(`${val}Z`), "dd/MM/yyyy")
}

export const compareDate = (a, b) => {
  const dateA = Date.parse(a);
  const dateB = Date.parse(b);
  if (dateA === dateB) {
    return 0;
  }
  return (dateA < dateB) ? -1 : 1;
};

export const reformatDate = (val, inputFormat, outputFormat) => {
  if (val === null || val === undefined) {
    return val
  }
  return format(parse(val, inputFormat, new Date()), outputFormat)
}