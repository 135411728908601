import { setPublisherArticleCategories } from './articleCategory';
import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from './alert';
import * as CommonMessages from '../constants/common';

export const setPublisherDetailedStats = (payload: any) => {
  return {
    type: ActionTypes.SET_DETAILED_STATS,
    payload: {
      payload
    }
  }
}

export const setPublisherAggregatedStats = (payload: any) => {
  return {
    type: ActionTypes.SET_AGGREGATED_STATS,
    payload: {
      payload
    }
  }
}

export const setStatsCountries = (list: any) => {
  return {
    type: ActionTypes.SET_STATS_COUNTRIES,
    payload: {
      list
    }
  }
}

export const setStatsCategories = (list: any) => {
  return {
    type: ActionTypes.SET_STATS_CATEGORIES,
    payload: {
      list
    }
  }
}

export const unsetStatisctics = () => {
  return {
    type: ActionTypes.UNSET_STATISTICS,
  }
}

export const setErrors = (errors: any) => {
  return {
    type: ActionTypes.SET_UNSET_RPM_ERRORS,
    payload: {
      errors
    }
  }
}

export function getPublisherDetailedStatistics(id, currentPage, pageSize, payload) {
  payload.country = payload.country === "ALL" ? null : payload.country
  payload.category = payload.category === "ALL" ? null : payload.category
  return (dispatch: any, getState: any) => {
    return ApiService.post(`/publisher/${id}/detailedStats/${currentPage}/${pageSize}`, payload)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            dispatch(setPublisherDetailedStats(body.data));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisherAggregatedStats(payload) {
  payload.country = payload.country === "ALL" ? null : payload.country
  payload.category = payload.category === "ALL" ? null : payload.category
  return (dispatch: any, getState: any) => {
    return ApiService.post(`/publisher/accountingStats`, payload)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            dispatch(setPublisherAggregatedStats(body.data));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisherStatsCountries(id) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${id}/distinctBidCountries`)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            const {distinctCountries} = body.data || [];
            dispatch(setStatsCountries(distinctCountries));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisherStatsCategories(id) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${id}/articleCategories`)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            const {articleCategories} = body.data || [];
            dispatch(setStatsCategories(articleCategories));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}
