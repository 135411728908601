/*
  ApiService singleton service for fewCents

  response object structure from api service
  { data: {}, status: 200, statusText: 'OK', config: {}, request: {} }
  "data" is the response that was provided by the server
  "status" is the HTTP status code from the server response
  "statusText" is the HTTP status message from the server response
  "headers" the HTTP headers that the server responded with
      All header names are lower cased and can be accessed using the bracket notation.
      Example: "response.headers['content-type']"

  "config" is the config object that was provided to "axios" for the request
  "request" is an XMLHttpRequest instance in the browser
*/

import axios from 'axios';
import { ApiBaseUrl, Domain } from '../config';
import { getAuthToken, hasAuthToken, removeAuthToken } from '../helpers';

// axios.defaults.baseURL = ApiBaseUrl;
// axios.defaults.headers.common['Content-Type'] = 'application/json';

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: 1000*60*2 ,
  responseType: 'json',
  validateStatus: function (status) {
    return status <= 500; // default
  },
  // proxy: {
  //   host: '127.0.0.1',
  //   port: 9000,
  //   auth: {
  //     username: 'mikeymike',
  //     password: 'rapunz3l'
  //   }
  // }
});

// Add interceptor to add Auth-token
axiosInstance.interceptors.request.use( config => {
  if(hasAuthToken()) {
    config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
  }
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      removeAuthToken();
      window.location.href = `${Domain}/login`
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

// Singleton service
class FewcentsApiService {  
  private static instance: FewcentsApiService;
  private axios: any;
  // constructor private for singleton
  private constructor() {
    this.axios = axiosInstance
  }
  
  // returns singleton instance of class
  public static getInstance(): FewcentsApiService {
    if(!this.instance) {
      this.instance = new FewcentsApiService();  
    }    
    return this.instance;
  }

  get(url: string): Promise<any> {
    return this.axios.get(url);
  }
  
  post (url: string, payload?: {[k:string]: any}): Promise<any> {
    return this.axios.post(url, payload);
  }

  put (url: string, payload?: {[k:string]: any}): Promise<any> {
    return this.axios.put(url, payload);
  }

  patch (url: string, payload?: {[k:string]: any}): Promise<any> {
    return this.axios.patch(url, payload);
  }

  delete(url: string): Promise<any> {
    return this.axios.delete(url);
  }  

  //TODO: takes promise array
  // parallel(requests: Promise<any>[]): Promise<any> {
  //   return axios.all([...requests])
  //   .then(axios.spread((...responses) => {
  //     // Both requests are now complete
  //     return { ...responses }      
  //   }));    
  // }
}

export const ApiService = FewcentsApiService.getInstance();
