import { SignalCellularNullRounded } from '@material-ui/icons';
import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  analytics: null,
  statistics: null,
  categories: [],
  countries: [],
}

export default (state = defaultState, action: IAction) => {
  const payload = action.payload || {};

  switch (action.type) {
    case Action.SET_DETAILED_STATS:
        return {
          ...state,
          analytics: payload,
        };
    case Action.SET_AGGREGATED_STATS:
      return {
        ...state,
        statistics: payload,
      };
    case Action.SET_STATS_COUNTRIES:
      return {
        ...state,
        countries: payload,
      };
    case Action.SET_STATS_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    case Action.UNSET_STATISTICS:
      return {
        ...defaultState
      }
    case Action.SET_UNSET_RPM_ERRORS:
      return {
        ...state,
        errors: payload.errors
      }
    default:
      return state;
  }
};