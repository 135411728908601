import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  text: null
}

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case Action.SET_ALERT_TEXT:
      return {
        ...state,
        ...action.payload,
      };
    case Action.CLEAR_ALERT_TEXT:
      return {
        ...defaultState
      };
    default:
      return state;
  }
};