import React, { useState } from 'react';
import { Input} from 'reactstrap';
import Widget from '../components/widget';
import { Getter } from '@devexpress/dx-react-core';
import { 
  EditingState,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel  
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  generateRows,
  defaultColumnValues,
} from './demo-data/generator';

const getRowId = row => row.id;

export default () => {
  const [columns] = useState([
    { name: 'id', title: 'ID' },
    { name: 'name', title: 'Name' },
    { name: 'gender', title: 'Gender' },
    { name: 'city', title: 'City' },
    { name: 'car', title: 'Car' },
  ]);

  // setting rows 
  const [rows, setRows] = useState(generateRows({
    columnValues: { id: ({ index }) => index+1 , ...defaultColumnValues },
    length: 200,
  }));
  
  // adding per page options
  const [pageSizes] = useState([5, 10, 15, 0]);

  // preventing edit in certain fields
  const [editingStateColumnExtensions] = useState([
    { columnName: 'id', editingEnabled: false },
    { columnName: 'car', editingEnabled: false },
  ]);

  // on action handler (create/edit/delete)
  const commitChanges = ({ added, changed, deleted }: any) => {
    let changedRows;
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }

    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }

    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = rows.filter(row => !deletedSet.has(row.id));
    }

    setRows(changedRows);
  };



/*  For data validation on save and create

Maps the rows to a single object in which each field are is a row IDs
and the field's value is true if the cell value is invalid (a column is required
but the cell value is empty)


const validate = (rows, columns) => Object.entries(rows).reduce(
  (acc, [rowId, row]) => ({
    ...acc,
    [rowId]: columns.some(column => column.required && row[column.name] === ''),
  }), {},
);

const columns = [
  { name: 'firstName', title: 'First Name', required: true },
  { name: 'lastName', title: 'Last Name', required: true },
  { name: 'phone', title: 'Phone' },
  { name: 'state', title: 'State' },
];

const onEdited = debounce(edited => setErrors(validate(edited, columns)), 250);

  <EditingState
    onRowChangesChange={onEdited}
    onCommitChanges={commitChanges}
  />


  <TableEditColumn
    showEditCommand
    showDeleteCommand
    cellComponent={props => <EditCell {...props} errors={errors} />}
*/

/* Adding custom action button to table actions

function cellComponent({ children, ...restProps }) {
  const currentRow = { ...restProps.row };
  // TODO add new unique ID
  currentRow.id = this.state.rows.length + 1;
  return (
    <TableEditColumn.Cell {...restProps}>
      {children}
      {!this.state.addedRows.length && (
        <Button
          color="primary"
          onClick={() => {
            const addedRows = this.state.addedRows.slice();
            addedRows.push(currentRow);
            this.setState({
              addedRows,
            });
          }}
        >
          Copy
        </Button>
      )}
    </TableEditColumn.Cell>
  );
}
*/

const genderSelector = ({ value, onValueChange, ...props }) => {
  return(
    <Input type="select" name="gender" value={value} onChange={event => onValueChange(event.target.value)}>
      <option value='Female'>Female</option>
      <option value='Male'>Male</option>
    </Input>
  )
};


const DropDownTypeProvider = props => (
  <DataTypeProvider formatterComponent={genderSelector} {...props} />
);

  return (
    <Widget>
      <div className="card" style={{ position: 'relative' }}>
        <Grid
          rows={rows}
          columns={columns}
          getRowId={getRowId}
        >
          <DropDownTypeProvider for={["gender"]} />
          <EditingState
            onCommitChanges={commitChanges}
            // editingRowIds={editingRowIds}
            // onEditingRowIdsChange={this.changeEditingRowIds}
            // rowChanges={rowChanges}
            // onRowChangesChange={this.changeRowChanges}
            // addedRows={addedRows}
            // onAddedRowsChange={this.changeAddedRows}
            // editingCells={editingCells}
            // onEditingCellsChange={setEditingCells}
            columnExtensions={editingStateColumnExtensions}
          />
          <SortingState
            defaultSorting={[{ columnName: 'city', direction: 'asc' }]}
                      />
          <IntegratedSorting />

          <PagingState
              defaultCurrentPage={0}
              defaultPageSize={10}
            />
          <IntegratedPaging />

          <Table />
          <TableHeaderRow showSortingControls />
          <TableEditColumn
            showAddCommand
            showDeleteCommand
            showEditCommand
            // cellComponent={this.cellComponent}  // custom actions 

          />
          <TableEditRow />

          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const result = [
                ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 140 }
              ];
              return result;
            }
            }
          />
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>
      </div>
    </Widget>

  );
};
