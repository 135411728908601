import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Row,
  Col,
} from 'reactstrap';
import {
  TextField,
  FormGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
  Box
} from '@material-ui/core';

import SharedModal from '../components/modal';
import ListErrors from '../components/listErrors'
import Widget from '../components/widget';
import {
  getPublishers,
  saveNewPublisher,
  setErrors,
  updatePublisher,
  cancelPublisher,
  getCurrencyList
} from '../actions/publisher';
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  DataTypeProvider,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableEditRow,
  TableEditColumn,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import { Getter } from '@devexpress/dx-react-core';
import { filterValue } from '../helpers/common';
import { defaultColumnWidthsPub as defaultColumnWidths, gridColumnsMappingsPub as gridColumnsMappings, pageSizesPub as pageSizes, defaultPageSizePub as defaultPageSize } from '../constants/columnDefs';

class Publishers extends React.Component<any, any> {
  
  constructor(props: any) {
    super(props);
    this.state = { rows: [], columns: [], editableColumns: [], 
      showCreateModal: false, showEditModal: false, deletePublisherId: null };
    this.createNewPublisher = this.createNewPublisher.bind(this);
    this.closeModalAndReset = this.closeModalAndReset.bind(this);
    this.handleNewFormInputChange = this.handleNewFormInputChange.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.updatePublisher = this.updatePublisher.bind(this);
    this.handleDeleteBtnClick = this.handleDeleteBtnClick.bind(this);
    this.deletePublisher = this.deletePublisher.bind(this);
    this.onRowChanges = this.onRowChanges.bind(this);
    this.getAndRenderPublishers = this.getAndRenderPublishers.bind(this);
  }

  componentDidMount() {
    this.getAndRenderPublishers();
  }

  getAndRenderPublishers() {
    this.buildColumns();
    this.buildEditableColumns();
    this.props.dispatch(getCurrencyList());
    this.props
      .dispatch(getPublishers())
      .then(() => {
        this.buildRows();
      })
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({
        name: column.name,
        title: column.title
      })
      )
    this.setState({ columns: gridColumns });
  }

  buildRows() {
    const { list } = this.props;
    let rows = list.map((publisher, i) => {
      const row = {
        index: i + 1,
        id: publisher.id,
        domain: publisher.domain,
        name: publisher.name,
        financePhone: publisher.financePhone,
        financeEmail: publisher.financeEmail,
        bookCurrency: publisher.bookCurrency.code,
        minPrice: publisher.minPrice,
        numFreeArticles: publisher.numFreeArticles
      };
      Object.keys(row).forEach(key => row[key] = filterValue(row[key]));
      return row;
    });

    this.setState({ rows });
  }

  buildEditableColumns() {
    const editableColumns = gridColumnsMappings.map(column => {
        return { columnName: column.name, editingEnabled: !!column.editable }
      });
    this.setState({ editableColumns });
  }

  getRowId(row) {
    return row.index;
  }

  onRowChanges({ added, changed, deleted }: any) {
    let changedRows;

    if (changed) {
      const { rows } = this.state;
      const { list } = this.props;
      const rowIndex: number = parseInt(Object.keys(changed)[0]);
      const changedValues = changed[rowIndex]
      const selectedPublisher = list[rowIndex - 1];
      const { financeEmail, financePhone, name, id } = selectedPublisher;

      this.props.dispatch(updatePublisher(id, {
        financeEmail,
        financePhone,
        name,
        ...changedValues
      }, () => {
        // build upadted grid data
        changedRows = [...rows];
        changedRows[rowIndex - 1] = {
          ...changedRows[rowIndex - 1],
          ...changedValues
        }

        // update component state for grid
        this.setState({ rows: changedRows });
      }
      ));
    }

    if (deleted) {
      const { list } = this.props;
      const deletedPublisher = list[deleted - 1];
      this.handleDeleteBtnClick(deletedPublisher.id)
    }
  }

  handleDeleteBtnClick(id) {
    // e.preventDefault();
    // e.stopPropagation();
    this.setState({ deletePublisherId: id });
  }

  deletePublisher() {
    const { deletePublisherId } = this.state;
    this
      .props
      .dispatch(cancelPublisher(deletePublisherId))
      .finally(this.closeModalAndReset)
  }

  createNewPublisher() {
    let bookCurrencyCode;
    const allowedChars = /^[a-zA-Z\s]*$/;

    this.setState({ errors: null });
    const { newPublisher } = this.state;
    const { user } = this.props;

    if (!newPublisher) {
      this.setState({ errors: { "Error": "Inavlid details" } });
      return
    }

    const { currency, ...rest } = newPublisher;


    if (!(newPublisher.name || '').trim()) {
      this.setState({ errors: { "Required:": "publisher name" } });
      return
    }

    if (!allowedChars.test(newPublisher.name)) {
      this.setState({ errors: { "Name:": "special characters are not allowed" } });
    }

    let payload = {
      ...rest,
      createdByUserId: user.id
    }

    if (currency) {
      bookCurrencyCode = this.props.currencies.find(k => {
        return k.id.toString() === currency.toString()
      });
      if (bookCurrencyCode) {
        payload = {
          ...payload,
          bookCurrencyCode: bookCurrencyCode.code
        }
      }
    }

    this.props.dispatch(saveNewPublisher(payload, this.closeModalAndReset));
  }

  closeModalAndReset() {
    this.setState({
      newPublisher: null,
      errors: null,
      showCreateModal: false,
      selectedPublisher: null,
      deletePublisherId: null
    });
    this.props.dispatch(setErrors(null))
    this.getAndRenderPublishers();
  }

  handleNewFormInputChange(e: any) {
    let newPublisher = this.state.newPublisher || {};
    let value = e.target.value;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    this.setState({ newPublisher: { ...newPublisher, [e.target.name]: value } });
  }

  toggleShowCreateModal() {
    const { showCreateModal } = this.state;
    this.setState({ showCreateModal: !showCreateModal });
  }

  updatePublisher() {
    const { selectedPublisher: { financeEmail, financePhone, name, domain } } = this.state;

    this.props.dispatch(updatePublisher(domain, {
      financeEmail,
      financePhone,
      name
    }, this.closeModalAndReset));
  }

  getNumFreeArticlesOptions() {
    let optionArray: any[] = [];
    optionArray.push(<option key={-1} value="">Select</option>)
    for (let i = 0; i < 100; i++) {
      optionArray.push(<option key={i} value={i}>{i}</option>);
    }
    return optionArray;
  }


  render() {
    const { currencies } = this.props;
    const { rows, columns, editableColumns } = this.state;
    const errors = this.state.errors || this.props.errors;
    const showDetailsSelector = ({ value, onValueChange, ...props }) => {
      const { row } = props;
      return (
        <a href='#' onClick={() => this.props.history.push(`/app/publishers/${row.id}`)} >{value}</a>
      )
    };
    const ShowDetailsTypeProvider = props => (
      <DataTypeProvider formatterComponent={showDetailsSelector} {...props} />
    );

    return (
      <Widget>
        <div className="table-responsive container-fluid">
          <Row className="mb-2 align-items-center">
            <Col>
              <h5 style={{ marginBottom: "0px" }}>Publishers</h5>
            </Col>
            <Col>
              <Button className="float-right" type="submit" style={{ marginTop: "10px", backgroundColor: "#21ce99" }} variant="contained" onClick={this.toggleShowCreateModal}>
                <i className="fas fa-plus" style={{ marginRight: 5 }}></i>New
              </Button>
            </Col>
          </Row>
          <Grid
            rows={rows}
            columns={columns}
            getRowId={this.getRowId}
          >
            <ShowDetailsTypeProvider for={["name"]} />
            <EditingState
              onCommitChanges={this.onRowChanges}
              columnExtensions={editableColumns}
            />
            <SortingState />
            <IntegratedSorting />

            <PagingState
              defaultCurrentPage={0}
              defaultPageSize={defaultPageSize}
            />
            <IntegratedPaging />
            <Table />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />

            <TableEditColumn
              showEditCommand
              // showDeleteCommand
            />

            <TableEditRow />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const result = [
                  ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                  { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 140 }
                ];
                return result;
              }}
            />

            <PagingPanel
              pageSizes={pageSizes}
            />
          </Grid>

          <SharedModal
            isPrimaryActionDestructive={false}
            show={this.state.showCreateModal}
            buttonLabel="Create"
            onSaveClick={this.createNewPublisher}
            onCancelClick={this.closeModalAndReset}
            saveBtnLabel="Create"
            cancelBtnLabel="Cancel"
            modalTitle="New Publisher">
            <Form ref="newPublisherForm">
              {errors && <ListErrors errors={errors} />}
              
              <FormGroup>

                <TextField
                  required
                  margin="dense"
                  variant="outlined"
                  size="small"
                  label="Domain"
                  type="text"
                  name="domain"
                  id="input-domain"
                  onChange={this.handleNewFormInputChange}
                />
              </FormGroup>
              <FormGroup>
                <FormControl required variant="outlined" margin="dense" size="small">
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    name="currency"
                    id="input-currency"
                    label="Currency"
                    onChange={this.handleNewFormInputChange}
                  >
                    <MenuItem key="-1" value="Select">Select</MenuItem>
                    {
                      currencies.map((currency, _) => {
                        return <MenuItem key={_} value={currency.id}>{currency.code}</MenuItem>
                      })

                    }
                  </Select>
                  <FormHelperText style={{ marginLeft: 0}}>Please note that Domain and Currency cannot be changed later</FormHelperText>
                </FormControl>
              </FormGroup>
              
              <Box m={2}>
              <Divider></Divider>
              </Box>

              <FormGroup>
                <TextField
                  required
                  margin="dense"
                  variant="outlined"
                  size="small"
                  label="Publisher Name"
                  type="text"
                  name="name"
                  id="input-name"
                  onChange={this.handleNewFormInputChange}
                />
              </FormGroup>
              <Box m={2}>
              <Divider></Divider>
              </Box>
              <FormGroup>
                <TextField
                  margin="dense"
                  required
                  variant="outlined"
                  size="small"
                  label="Phone"
                  type="text"
                  name="financePhone"
                  id="input-finance-phone"
                  onChange={this.handleNewFormInputChange}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  margin="dense"
                  variant="outlined"
                  size="small"
                  label="Email"
                  type="text"
                  name="financeEmail"
                  id="input-finance-email"
                  onChange={this.handleNewFormInputChange}
                />
              </FormGroup>
            </Form>
          </SharedModal>

          {this.state.deletePublisherId &&
            <SharedModal
              isPrimaryActionDestructive={true}
              show={true}
              onSaveClick={this.deletePublisher}
              onCancelClick={this.closeModalAndReset}
              saveBtnLabel="Delete"
              cancelBtnLabel="Cancel"
              modalTitle="Delete Publisher">
              <div className="mt-3 mb-3">
                Are you sure you want to delete publisher?
                </div>
            </SharedModal>
          }
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (appState: any) => {
  return {
    list: appState.publishers.list,
    errors: appState.publishers.errors,
    currencies: appState.publishers.currencies,
    user: appState.auth.user
  }
};

export default connect(mapStateToProps)(Publishers);