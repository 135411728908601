import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListErrors from '../components/listErrors';
import { onLoginSubmitForm, resetAuth } from '../actions/auth';
import Widget from '../components/widget';
import { Row, Col, Form } from 'reactstrap';
import style from '../styles/login.module.scss';
import { TextField, FormGroup, Button } from '@material-ui/core';
import { WebsiteTitle } from '../config';

class Login extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {}
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.redirectInactiveToActivationPage = this.redirectInactiveToActivationPage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(resetAuth());
  }

  handleKeyPress(e: any) {
    if (e.charCode === 13) {
      this.setState({ errors: null });
      this.handleFormSubmit(e);
    }
  }

  handleFieldUpdate(e: any, field: any) {
    this.setState({ [field]: e.target.value });
  }

  handleFormSubmit(e: any) {
    e.preventDefault();
    const errors: any = {};
    this.setState({ errors });

    const { email, password } = this.state;
    if (!email || !password) {
      errors['Required:'] = 'email or password missing';
      return this.setState({ errors });
    }

    this.props.dispatch(onLoginSubmitForm({ email, password }, this.redirectInactiveToActivationPage));
  }

  componentDidUpdate(_previousProps: any) {
    const { user, isAuthenticated, inProgress } = this.props;
    // after successfull registration page should be redirected to login
    if (user && isAuthenticated && !inProgress) {
      this.redirectAfterSuccessfullLogin();
    }
  }

  redirectInactiveToActivationPage(id: any) {
    this.props.history.push(`/account/${id}/activation`);
  }

  redirectAfterSuccessfullLogin() {
    this.props.history.push('/app/profile');
  }

  getErrors() {
    if (Object.keys(this.state.errors || {}).length > 0) {
      return this.state.errors;
    }
    if (Object.keys(this.props.errors || {}).length > 0) {
      return this.props.errors;
    }
    return null;
  }

  render() {
    const errors = this.getErrors();

    return (
      <div className={style.root}>
        <Row>
          <Col>
            <Widget className={style.widget}>
              <h2 className={style.title}>{WebsiteTitle}</h2>
              <div style={{ textAlign: 'left', color: '#666' }}>
                <span>Use your Email and Password to Login</span>
              </div>
              <Form>
                <FormGroup>
                  <TextField
                    margin="normal"
                    id="email"
                    required
                    name="email"
                    onKeyPress={this.handleKeyPress}
                    type="email"
                    variant="outlined"
                    size="small"
                    label="Email Address"
                    onChange={(e) => this.handleFieldUpdate(e, 'email')}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    margin="normal"
                    id="password"
                    required
                    name="password"
                    type="password"
                    variant="outlined"
                    size="small"
                    label="Password"
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => this.handleFieldUpdate(e, 'password')}
                  />
                </FormGroup>
                <p className="fs-sm text-muted">
                  By logging in you accept <br />
                  our{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#21ce99' }}
                    href="https://fewcents.co/client_terms.html"
                  >
                    {' '}
                    Terms{' '}
                  </a>
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#21ce99' }}
                    href="https://fewcents.co/privacy.html"
                  >
                    {' '}
                    Privacy Policy
                  </a>
                </p>
                <FormGroup>
                  <Button
                    type="submit"
                    style={{ marginTop: '10px', backgroundColor: '#1ab394' }}
                    variant="contained"
                    onClick={this.handleFormSubmit}
                    disabled={this.props.inProgress}
                  >
                    {this.props.inProgress ? 'Loading...' : 'Login'}
                  </Button>
                </FormGroup>
                {errors && !this.props.inProgress && <ListErrors errors={errors} />}
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Link to="/register" className="fs-sm" style={{ color: '#21ce99' }}>
                    New User
                  </Link>
                  <Link to="/forgot-password" className="fs-sm" style={{ color: '#21ce99' }}>
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    ...appState.auth
  };
};

export default connect(mapStateToProps)(Login);
