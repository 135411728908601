import { connect } from 'react-redux';
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import s from '../styles/header.module.scss';
import userIcon from '../images/user.png';

class Header extends React.Component<any, any> {
  static propTypes = {
    sidebarToggle: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarToggle: () => {},
  };

  constructor(props: any) {
    super(props);
    this.state = { isOpen: false };
    this.doLogout = this.doLogout.bind(this);
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  doLogout = () => {
    this.props.history.push('/logout');
  }

  render() {
    const { isOpen } = this.state;
    const { user } = this.props;

    return (
      <Navbar className={s.root}>
        <Nav>
          <NavItem
            className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
            href="#"
            onClick={this.props.sidebarToggle}
          >
            <i className="fa fa-bars fa-2x" style={{ color:"white" }} />
          </NavItem>
        </Nav>
        <Nav className="ml-auto">
          <Dropdown isOpen={isOpen} toggle={this.toggleDropdown} >
            <DropdownToggle nav>
              <img src={userIcon} alt="" />
              <span style={{color: 'white', marginLeft:"5px" }}>{user.name}</span>
              <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isOpen})} style={{color: 'white'}}/>
              
            </DropdownToggle>
            <DropdownMenu style={{width: '100%'}}>
              <DropdownItem>
                <NavLink to="/logout">
                  <i className="fas fa-sign-out-alt mr-sm" style={{color: 'black'}} aria-hidden="true"></i>
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(Header);
