import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthService } from '../services';
import { setCurrentUser } from '../actions/auth'
import { getPublishers } from '../actions/publisher';

export default function (ComposedComponent: any) {
  class Authentication extends Component<any, any> {
    constructor(props: any) {
      super(props);
      this.check();
    }

    check() {
      // localstorage has no auth token
      if(AuthService.userNotLoggedIn()) {
        return this.props.history.push('/login');
      } // current user already set in store 
      if(this.props.isAuthenticated) {
        return;
      }
      // localstorage has auth token but no current user set to store
      this.fetchAndSetCurrentUser()
    }

    private fetchAndSetCurrentUser() {
      AuthService
        .getUser()
        .then((response: any) => {
          const { data } = response.data;
          //logout if user not found
          if(!data) {
            AuthService.logOut();
            throw new Error('Unauthorized request.')
          }

          this.props.dispatch(setCurrentUser(data, {}));
          this.props.dispatch(getPublishers());
        })
        .catch((error: Error) => {
          console.log("Error:", error.message);
          this.props.history.push('/login');
        });
    }

    render() {
      const isAuthenticated = this.props.isAuthenticated;
      return (isAuthenticated ? <ComposedComponent /> : null);
    }
  }
  const mapStateToProps = (state: any) => {
    return { isAuthenticated: state.auth.isAuthenticated };
  }
  return connect(mapStateToProps)(Authentication);
}