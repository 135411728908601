import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import s from '../styles/footer.module.scss';
class Footer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <footer className={cx(s.root)}>
        <div className={s.container} style={{textAlign: "center"}}>
          <span>© &nbsp;Powered by Monetise Pte. Ltd. | Singapore </span>
          <a href="mailto:support@fewcents.co" target="_blank" rel="noopener noreferrer"> | <u>Email Support</u></a>
        </div>
      </footer>
    );
  }
}

export default Footer;