import React from 'react';
import { connect } from 'react-redux';
import { Button, CardBody, CardText, CardTitle, Card } from 'reactstrap';
import Widget from '../components/widget';
import style from '../styles/activation.module.scss';
import { resendAccountActivationLink, onLoginSubmitForm } from '../actions/auth';
import { getAccountActivationCookie } from '../helpers/common';
import { config, WebsiteTitle } from '../config';

class AccountActivation extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
    this.handleContinueLoginClick = this.handleContinueLoginClick.bind(this);
    this.handleResendActivationClick = this.handleResendActivationClick.bind(this);
  }

  handleContinueLoginClick(e: any) {
    const accountActivationDetails = getAccountActivationCookie();
    if (accountActivationDetails) {
      const userDetails = JSON.parse(accountActivationDetails);
      this.props.dispatch(onLoginSubmitForm(userDetails));
    } else {
      this.props.history.push('/login');
    }
  }

  componentDidUpdate(_previousProps: any) {
    const { user, isAuthenticated, inProgress } = this.props;
    // after successfull registration page should be redirected to login
    if (user && isAuthenticated && !inProgress) {
      this.redirectAfterSuccessfullLogin();
    }
  }

  redirectAfterSuccessfullLogin() {
    this.props.history.push('/app/profile');
  }

  handleResendActivationClick(e: any) {
    const { id } = this.props.match.params;
    this.props.dispatch(resendAccountActivationLink(id));
  }

  render() {
    return (
      <div className={style.root}>
        <Widget className={style.widget}>
          <h2 className={style.title}>{WebsiteTitle}</h2>
          <h4 style={{ textAlign: 'left' }}>
            <strong>Account Activation</strong>
          </h4>
          <Card>
            <CardBody>
              <CardTitle></CardTitle>
              <CardText>
                Your account is not activated yet. An email with activation link has been sent to your email account.
                Please check your email and follow instructions. If you have not received activation email, press below
                button to resend email. Once your account has been verified you can proceed to login.
              </CardText>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <Button color="success" style={{ borderRadius: '4px' }} onClick={this.handleResendActivationClick}>
                  Resend Activation Email
                </Button>

                <Button color="success" style={{ borderRadius: '4px' }} onClick={this.handleContinueLoginClick}>
                  Continue
                </Button>
              </div>
            </CardBody>
          </Card>
        </Widget>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    ...appState.auth
  };
};

export default connect(mapStateToProps)(AccountActivation);
