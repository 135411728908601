import React from 'react';
import cx from 'classnames';
import { Switch, Route, withRouter } from 'react-router';
import NotFoundComponent from './404';
import Header from './header';
import Profile from '../containers/profile';
import Sidebar from './sidebar';
import Publishers from '../containers/publishers';
import PublisherEditUpdate from '../containers/publisherEditUpdate';
import Articles from '../containers/articles';
import RPM from '../containers/rpm';
import RPMTipJar from '../containers/rpm-tipjar'
import Wallets from '../containers/wallets';
import Activities from '../containers/activities';
import ArticleCategory from '../containers/articleCategory'
import layoutStyle from '../styles/layout.module.scss';
import DataGrid from '../containers/grid';
import Footer from './footer';
class Layout extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    })
  }

  render() {
    const { root, sidebarOpen, wrap, content, headerParent } = layoutStyle;

    return (
      <div className="container-fluid">
        <div className={root}>
          <Sidebar />
          <div
            className={cx(wrap, {[sidebarOpen]: this.state.sidebarOpen})}
          >
            <div className={cx('flex-row', headerParent)}>
              <Header
                sidebarToggle={this.toggleSidebar}
              />
            </div>
            <main className={content}>
              <Switch>
                <Route path="/app/profile" exact component={Profile} />
                <Route path="/app/publishers" exact component={Publishers} />
                <Route path="/app/publishers/:id" exact component={PublisherEditUpdate} />
                <Route path="/app/publishers/:id/rpm" exact component={RPM} />
                <Route path="/app/publishers/:id/rpm-tipjar" exact component={RPMTipJar} />
                <Route path="/app/publishers/:id/articles" exact component={Articles} />
                <Route path="/app/publishers/:id/artcileCategories" exact component={ArticleCategory} />
                <Route path="/app/publishers/:id/wallets" exact component={Wallets} />
                <Route path="/app/publishers/:id/activities" exact component={Activities} />
                <Route path="/app/grid" exact component={DataGrid} />
                <Route component={NotFoundComponent} />
              </Switch>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Layout);
