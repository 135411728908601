import React from 'react';
import { 
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Row,
  Col,
  Badge
} from 'reactstrap';
import { logoutDevice, logoutAllDevices } from '../actions/device';
import { defaultColumnWidthsDev as defaultColumnWidths, gridColumnsMappingsDev as gridColumnsMappings, pageSizesDev as pageSizes, defaultPageSizeDev as defaultPageSize } from '../constants/columnDefs';

export default class Devices extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = ({
      rows: [],
      columns: []
    });
    this.handleDeviceLogout = this.handleDeviceLogout.bind(this);
    this.handleLogoutAllDevices = this.handleLogoutAllDevices.bind(this);
  }

  componentDidMount() {
    const { devices } = this.props;
    this.buildColumns();
    this.buildRows(devices);
  }

  componentWillReceiveProps(nextProps: any) {
    const nextDevices = nextProps.devices;
    this.buildRows(nextDevices);
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({ 
        name: column.name,
        title: column.title
      })
    )
    this.setState({ columns: gridColumns });
  }

  buildRows(devices) {
    let rows = devices.map((device, i) => {
      return {
        index: i+1,
        browser:  device.browser,
        os: device.os,
        deviceKey: device.deviceKey,
        device: device,
        isActive: !!device.idToken
      };
    });
    this.setState({ rows });
  }

  getRowId(row) {
    return row.index;
  }

  handleDeviceLogout(device: any) {
    this.props.dispatch(logoutDevice(device));
  }

  getCurrentUserDevice() {
    const { currentDeviceId, devices } = this.props;

    return devices.find(device => {
      return device.deviceKey === currentDeviceId
    })
  }

  handleLogoutAllDevices() {
    const currentDevice = this.getCurrentUserDevice()
    this.props.dispatch(logoutAllDevices(currentDevice));
  }

  render() {
    const { rows, columns } = this.state;
    const { currentDeviceId } = this.props;

    // for device status
    const statusSelector = ({ value, onValueChange, ...props }) => { 
      const isActive = props.row.isActive

      return (
        <Badge color={ isActive ? "success" : "danger"} className="text-white">
          { isActive ? 'Active' : 'Inactive' }
        </Badge>
      )
    }

    const StatusTypeProvider = props => (
      <DataTypeProvider formatterComponent={statusSelector} {...props} />
    ); // for device status


    // for logout
    const logoutSelector = ({ value, onValueChange, ...props }) => { 
      const { device, deviceKey } = props.row;
      const isActive = props.row.isActive
      const currentDevice = deviceKey === currentDeviceId;
      const shouldBeDisabled = !isActive || currentDevice; 
      const title = currentDevice ? "Current device" : (isActive ? "Logout" : "Inactive");

      return ( 
        <button 
          className="btn btn-success btn-sm pull-center"
          style={shouldBeDisabled ? {cursor: "not-allowed"} : {}}
          title={title}
          disabled={shouldBeDisabled}
          onClick={e => { this.handleDeviceLogout(device)}}
          >
          Logout
        </button>
      )
    }

    const LogoutTypeProvider = props => (
      <DataTypeProvider formatterComponent={logoutSelector} {...props} />
    ); // for device logout

    return (
      <div>
        <Row className="mb-2 align-items-center">
          <Col>
            <h5 style={{ marginBottom: "0px", color: "#21ce99"}}>Devices</h5>
          </Col>
          <Col>
            <button 
              className="btn btn-sm btn-success float-right"
               onClick={this.handleLogoutAllDevices}
              >
              Logout All
            </button>
          </Col>
        </Row>

        <Grid
          rows={rows}
          columns={columns}
          getRowId={this.getRowId}
          >
          <StatusTypeProvider for={["isActive"]} />
          <LogoutTypeProvider for={["logout"]} />

          <SortingState/>
          <IntegratedSorting />

          <PagingState
              defaultCurrentPage={0}
              defaultPageSize={defaultPageSize}
            />
          <IntegratedPaging />

          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls />
          <PagingPanel
            pageSizes={pageSizes}
          />
        </Grid>
      </div>
    );
  }
}
