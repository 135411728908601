import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as CommonMessages from '../constants/common';
import { showFailureMessage } from './toast';

function setPublisherLedger(list: any[]) {
  return {
    type: ActionTypes.SET_LEDGERS,
    payload: {
      list
    }
  }  
}

function setPublisherFbids(list: any[]) {
  return {
    type: ActionTypes.SET_FBIDS,
    payload: {
      list
    }
  }
}

function setPublisherHistory(payload: any) {
  return {
    type: ActionTypes.SET_HISTORY,
    payload: {
      payload
    }
  }
}

export function resetActivities() {
  return {
    type: ActionTypes.RESET_ACTIVITIES
  }
}

export function getPublisherLedgers(publisherId) {
  return (dispatch: any, getState: any) => {
 
    return ApiService.get(`/publisher/${publisherId}/ledger`)
      .then(response => {
        const { success, data, message } = response.data;
        // failure 
        if(response.status !== 200 || !success) {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          return showFailureMessage(errorMessage);
        }
        // success response data
        dispatch(setPublisherLedger(data.publisherledgers));
      })
      .catch(error => {
        showFailureMessage(`${CommonMessages.COMMON_FAILURE} ${error.message}`);
      });
  }  
}

export function getPublisherFbids(publisherId) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${publisherId}/fewCentsBids`)
      .then(response => {
        const { success, data, message } = response.data;

        // failure 
        if(response.status !== 200 || !success) {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          return showFailureMessage(errorMessage);
        }
        // success response data
        dispatch(setPublisherFbids(data.fewcentsbids));
      })
      .catch(error => {
        showFailureMessage(`${CommonMessages.COMMON_FAILURE} ${error.message}`);
      });
  }  
}

export function reverseBid(publisherId, fewcentsTransactionId) {
  return (dispatch: any, getState: any) => {
    return ApiService.put(`/publisher/${publisherId}/reverseBid/${fewcentsTransactionId}`,{})
      .then(response => {
        const { success, data, message } = response.data;
        // failure 
        if(response.status !== 200 || !success) {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          return showFailureMessage(errorMessage);
        }
        // success response data
        const { activities: { publisherHistory }} = getState();
        const index = publisherHistory.payload.items.findIndex(y => y.id === data);
        const bid = publisherHistory.payload.items[index];
        if (bid) {
          bid.isReversalAllowed = false;
          publisherHistory.payload.items[index] = bid;
        }
        setPublisherHistory(publisherHistory);
      })
      .catch(error => {
        showFailureMessage(`${CommonMessages.COMMON_FAILURE} ${error.message}`);
      });
  }  
}

export function getPublisherBids(publisherId, currentPage, pageSize) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${publisherId}/history/${currentPage}/${pageSize}`)
      .then(response => {
        const { success, data, message } = response.data;

        // failure 
        if(response.status !== 200 || !success) {
          const errorMessage = message || CommonMessages.COMMON_FAILURE;
          return showFailureMessage(errorMessage);
        }
        // success response data
        dispatch(setPublisherHistory(data));
      })
      .catch(error => {
        showFailureMessage(`${CommonMessages.COMMON_FAILURE} ${error.message}`);
      });
  }  
}