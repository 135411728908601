import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from '../actions/alert';
import * as CommonMessages from '../constants/common';
import { showSuccessMessage, showFailureMessage } from './toast';

const setPublishers = (list: any[]) => {
  return {
    type: ActionTypes.SET_PUBLISHERS,
    payload: {
      list
    }
  }
}

const setSelectedPublisher = (publisher: any) => {
  return {
    type: ActionTypes.SET_SELECTED_PUBLISHER,
    payload: {
      publisher
    }
  }
}

export const setErrors = (errors: any) => {
  return {
    type: ActionTypes.SET_UNSET_PUBLISHER_ERRORS,
    payload: {
      errors
    }
  }
}

export const inProcess = (inProcess: boolean) => {
  return {
    type: ActionTypes.PUBLISHER_API_PROCESSING,
    payload: {
      inProcess
    }
  }
}

export const setCurrencies = (currencies: any[]) => {
  return {
    type: ActionTypes.SET_CURRENCIES,
    payload: {
      currencies
    }
  }
}

export function getPublishers() {
  return (dispatch: any, getState: any) => {
    const { auth: { user } } = getState();

    dispatch(inProcess(true));

    return ApiService.get(`/publisher/getAll/${user.email}`)
      .then(response => {
        if (response.status === 200) {
          const { data } = response.data;
          if (data && data.publishers) {
            dispatch(setPublishers(data.publishers));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }

        dispatch(inProcess(false));
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`));
        dispatch(inProcess(false));
      })
  }
}

export function saveNewPublisher(payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.post('/publisher/create', payload)
      .then(response => {
        const { success } = response.data;
        dispatch(inProcess(false));

        if (response.status === 200 && success) {
          showSuccessMessage('Created Successfully')
          dispatch(getPublishers());
          if (callback)
            callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updatePublisher(id, payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/update`, payload)
      .then(response => {
        const { success } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          showSuccessMessage('Updated Successfully')
          dispatch(getPublishers());
          if (callback) callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updatePublisherBrandingAndContact(id, payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/updateBranding`, payload)
      .then(response => {
        const { success } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          showSuccessMessage('Updated Successfully')
          dispatch(getPublishers());
          if (callback) callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updatePublisherArticlePrice(id, payload: any, callback: any) {
  return (dispatch: any) => {
    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/updatePriceSetting`, payload)
      .then((response: any) => {
        const { success } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          showSuccessMessage('Updated Successfully')
          dispatch(getPublishers());
          if (callback) callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updatePublisherPaywallSettings(id, payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/updatePaywallSettings`, payload)
      .then(response => {
        const { success } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          showSuccessMessage('Updated Successfully')
          dispatch(getPublishers());
          if (callback) callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updatePublisherPaywallCustomization(id, payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/updatePaywallCustomization`, payload)
      .then(response => {
        const { success } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          showSuccessMessage('Updated Successfully')
          dispatch(getPublishers());
          if (callback) callback();
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisher(id: string | number) {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.get(`/publisher/${id}`)
      .then(response => {
        const { success, data } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          dispatch(setSelectedPublisher(data));
        } else {
          const message = (response.data || {}).message || CommonMessages.COMMON_FAILURE;
          dispatch(setErrors({ "Server Error:": message }));
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function cancelPublisher(id: string) {
  return (dispatch: any, getState: any) => {
    const { publishers: { list } } = getState();
    const publisher = list.find(publisher => {
      return parseInt(publisher.id) === parseInt(id)
    });

    dispatch(inProcess(true));
    return ApiService.put(`/publisher/${id}/cancel`, { publisher })
      .then(response => {
        const { success, message } = response.data;
        const errorMessage = message || 'failed to cancel publisher';
        dispatch(inProcess(false));
        // success response
        if (response.status === 200 && success) {
          return showSuccessMessage('Cancelled Successfully')
        }
        // failed to cancel publisher
        showFailureMessage(errorMessage);
      })
      .catch(error => {
        dispatch(inProcess(false));
        showSuccessMessage(`${CommonMessages.COMMON_FAILURE} ${error.message}`);
      })
  }
}

export function getCurrencyList() {
  return (dispatch: any, getState: any) => {
    dispatch(inProcess(true));
    return ApiService.get("/currency/all")
      .then(response => {
        const { success, message, data } = response.data;
        dispatch(inProcess(false));
        if (response.status === 200 && success) {
          const currencies = data.currencies || [];
          dispatch(setCurrencies(currencies));
        } else {
          const errorMessage = message || 'failed to cancel publisher';
          throw new Error(`Error: ${errorMessage}`);
        }
      })
      .catch(error => {
        dispatch(inProcess(false));
        console.log(error.message)
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}
