import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Widget from '../components/widget';
import { 
  unsetStatiscticsTipJar,
  getPublisherAggregatedStatsTipJar,
  getPublisherStatsCategoriesTipJar,
  getPublisherStatsCountriesTipJar,
} from '../actions/rpm-tipjar';
import { getPublisher } from '../actions/publisher';

import {
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  Button,
  Spinner,
} from 'reactstrap';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import { numberWithCommasAndFractions, 
  numberWithCommas,
} from '../helpers/common';
import { defaultColumnWidthsRpm as defaultColumnWidths, 
  gridColumnsMappingsRpm as gridColumnsMappings, 
} from '../constants/columnDefs';

class RPMTipJar extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { 
      statistics: null,
      rows: [], 
      columns: [],
      searchValue: "",
      totalCount: 0,
      currentPage: 0,
      pageSize: 50,
      loading : false,
      startDate: this.getInitStartDate(),
      endDate: new Date(),
      country : "ALL",
      category : "ALL",
      showDetails : false,
      showSpinner : false,
    };
    this.setSearchState = this.setSearchState.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.getStatistics = this.getStatistics.bind(this);
  }

  getInitStartDate() {
    let d = new Date();
    d.setDate(d.getDate()-30);
    return d
  }
  
  setSearchState(e) {
    this.setState({ searchValue: e });
  }

  setCurrentPage(e) {
    this.setState({ currentPage: e });
    const { publisher } = this.props;
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.buildColumns();
    this.getAggregatedStats(id, true);
  }

  componentWillReceiveProps(nextProps: any) {
    const { id } = this.props.match.params;
    const nextId = nextProps.match.params.id;

    if(id !== nextId) {  
  
      this.setState({showDetails : false, totalCount : 0, currentPage : 0, 
        rows: [], country: "ALL", category: "ALL",
        startDate: this.getInitStartDate(),
        endDate: new Date(),
      })
      
      this.getAggregatedStats(nextId, true);
    }
  }

  setupReferenceData(id) {
    // this.props.dispatch(getPublisherStatsCountriesTipJar(id))
    // this.props.dispatch(getPublisherStatsCategoriesTipJar(id))
  }

  componentWillUnmount() {
    this.props.dispatch(unsetStatiscticsTipJar());
  }

  getStatistics() {
    const { id } = this.props.match.params;
    this.getAggregatedStats(id, false);
  }

  getAggregatedStats(id, setPublisher) {
    if (setPublisher) {
      this.props.dispatch(getPublisher(id));
    }
  
    this.setState({showDetails : false, 
      totalCount : 0, 
      currentPage : 0, 
      rows: [], 
      loading: true, 
      showSpinner : true
     })

    const payload = {
      publisherId : id,
      startDate : this.state.startDate,
      country : this.state.country,
      category : this.state.category,
      endDate: this.state.endDate,
    }
    
    this.props
      .dispatch(getPublisherAggregatedStatsTipJar(payload))
      .then(() => {
        const { statistics }  = this.props;
        this.setState({
          statistics : statistics.payload,
          loading: false, 
          showSpinner : false
        })
        if (setPublisher) {
          this.setupReferenceData(id)
        }
      })
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({ 
        name: column.name,
        title: column.title
      })
    )
    this.setState({ columns: gridColumns });
  }

  getRowId(row) {
    return row.index;
  }
  
  setStartDate(date) {
    var utcDate = new Date(date.getTime() - new Date().getTimezoneOffset() * 60000);
    this.setState({ startDate: utcDate })
  }

  setEndDate(date) {
    var utcDate = new Date(date.getTime() - new Date().getTimezoneOffset() * 60000);
    this.setState({endDate : utcDate})
  }
  
  handleCountryChange(e: any) {
    this.setState({country : e.target.value})
  }

  handleCategoryChange(e: any) {
    this.setState({category : e.target.value})
  }

  render() {
    const { publisher, categories, countries } = this.props;

    const { rows, columns, searchValue, totalCount, currentPage, pageSize, loading, 
      statistics, showDetails, startDate, endDate, showSpinner} = this.state;
    
    const minStartDate = new Date();
    minStartDate.setDate(endDate.getDate() - 365);

    const maxEndDate = new Date();
    if(startDate.getDate() + 365 > maxEndDate) {
      maxEndDate.setDate(startDate.getDate() + 365);
    }

    return (
      <Widget>
        <div className="table-responsive container-fluid" style={{ minHeight: "350px"}}>
        <h5>Statistics { publisher && <span>(Publisher: {publisher.name} | Currency: {publisher.bookCurrency.code})</span>} </h5>
        <br>
        </br>
        <div id="filterContainer">
          <Row className="mb-2 align-items-center">
            <Col>
              <span>Statistics Start Date: </span>
              <DatePicker 
              selected={startDate} 
              onChange={date => this.setStartDate(date)} 
              dateFormat="yyyy-MM-dd"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              maxDate={new Date()}
              minDate={minStartDate}
              />
            </Col>
            <Col>
              <span>Statistics End Date: </span>
              <DatePicker 
              selected={endDate} 
              onChange={date => this.setEndDate(date)} 
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="yyyy-MM-dd"
              maxDate={maxEndDate}
              />
            </Col>
            {/* <Col>
              <span>Country: </span>
              <Select
              autoWidth={true}
              defaultValue={"ALL"}
              labelId="countryId"
              id="countryId"
              value={this.state.countryFilter}
              onChange={this.handleCountryChange}
              label="Select Country"
						  >				
                <MenuItem value="ALL">ALL</MenuItem>
                {
                  (countries && countries.list) ? (
                    countries.list.map((val, i) => {
                      return (<MenuItem key={val as string} value={val as string}>{val as string}</MenuItem>)
                    })) : ""
                }									
					    </Select>
            </Col>
            <Col>
              <span>Category: </span>
              <Select
              autoWidth={true}
              defaultValue={"ALL"}
              labelId="categoryId"
              id="categoryId"
              value={this.state.categoryFilter}
              onChange={this.handleCategoryChange}
              label="Select Category"
						  >		
                <MenuItem value="ALL">ALL</MenuItem>
                {
                  (categories && categories.list) ? (
                    categories.list.map((val, i) => {
                      return (<MenuItem key={val.category as string} value={val.category as string}>{val.category as string}</MenuItem>)
                    })) : ""
                }	
					    </Select>
            </Col> */}
            <Col>
              <Button className="float-right" type="submit" style={{ marginTop: "10px", backgroundColor: "#21ce99" }} variant="contained" onClick={this.getStatistics}>
                <i className="fas" style={{ marginRight: 5 }}></i>Get Statistics
              </Button>
            </Col>  
          </Row>
        </div>
        {showSpinner &&  
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner animation='grow'></Spinner>
              <span className="ml-4"> {"Loading..."}</span>
            </div>
          }
        <br /><br />
        {!showSpinner && statistics && 
          <div>
            <div style={{border:"thin solid #000000", overflow:"hidden"}}>
            <Row className="mb-2 align-items-center" style={{backgroundColor:"#21ce99"}}>
              <Col className="ml-2"><span>Fewcents Funnel</span></Col>
              <Col className="ml-2"><span>Views</span></Col>
              <Col className="ml-2"><span>Conversions</span></Col>
            </Row>
            <Row className="mb-2 align-items-center">
              <Col className="ml-2"><span>Total FC Page Views</span></Col>
              <Col className="ml-2"><span>{statistics.totalPageViews ? statistics.totalPageViews : 0 }</span></Col>
              <Col className="ml-2"><span>-</span></Col>
            </Row>
            <Row className="mb-2 align-items-center">
              <Col className="ml-2"><span>Logged in Page Views</span></Col>
              <Col className="ml-2"><span>{statistics.loggedInPageViews ? statistics.loggedInPageViews : 0 }</span></Col>
              <Col className="ml-2"><span>{statistics.totalPageViews && statistics.loggedInPageViews  ? numberWithCommasAndFractions((statistics.loggedInPageViews/statistics.totalPageViews) * 100, 1) : 0 } %</span></Col>
            </Row>
            <Row className="mb-2 align-items-center">
              <Col className="ml-2"><span>Paid Tips</span></Col>
              <Col className="ml-2"><span>{statistics.paidUnlocks ? statistics.paidUnlocks : 0 }</span></Col>
              <Col className="ml-2"><span>{statistics.loggedInPageViews && statistics.paidUnlocks  ? numberWithCommasAndFractions((statistics.paidUnlocks/statistics.loggedInPageViews) * 100, 1) : 0 } %</span></Col>             
            </Row>
            </div>
            <br></br>
            <div style={{border:"thin solid #000000", overflow:"hidden"}}>
            <Row className="mb-2 align-items-center" style={{backgroundColor:"#21ce99"}}>
              <Col className="ml-2"><span>{statistics.currency}</span></Col>
              <Col className="ml-2"><span>Total Tips</span></Col>
              <Col className="ml-2"><span></span></Col>
            </Row>
            <Row className="mb-2 align-items-center">
              <Col className="ml-2"><span>GMV</span></Col>
              <Col className="ml-2"><span>{statistics.currency} {statistics.gmvTotalUnlocked ? numberWithCommas(statistics.gmvTotalUnlocked) : 0 }</span></Col>
              <Col className="ml-2"><span></span></Col>
            </Row>
            <Row className="mb-2 align-items-center" >
              <Col className="ml-2"><span>RPM</span></Col>
              <Col className="ml-2"><span>{statistics.currency} {statistics.paidRPM ? numberWithCommas(statistics.paidRPM) : 0 }</span></Col>
              <Col className="ml-2"><span></span></Col>
            </Row>
            </div>
            <br></br>

            {
              statistics.currency !== 'USD'
              && 
              <div style={{border:"thin solid #000000", overflow:"hidden"}}>
              <Row className="mb-2 align-items-center" style={{backgroundColor:"#21ce99"}}>
                <Col className="ml-2"><span>USD</span></Col>
                <Col className="ml-2"><span>Total Tips</span></Col>
                <Col className="ml-2"><span></span></Col>
              </Row>
              <Row className="mb-2 align-items-center">
                <Col className="ml-2"><span>GMV</span></Col>
                <Col className="ml-2"><span>USD {statistics.gmvTotalUnlocked_USD ? numberWithCommas(statistics.gmvTotalUnlocked_USD) : 0 }</span></Col>  
                <Col className="ml-2"><span></span></Col>
              </Row>
              <Row className="mb-2 align-items-center">
                <Col className="ml-2"><span>RPM</span></Col>
                <Col className="ml-2"><span>USD {statistics.paidRPM_USD ? numberWithCommas(statistics.paidRPM_USD) : 0 }</span></Col>
                <Col className="ml-2"><span></span></Col>
              </Row>
              </div>
          }
          </div>
        }
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (appState: any) => {
  return {
    publisher: appState.publishers.selectedPublisher,
    statistics: appState.rpmtipjar.statistics,
    countries: appState.rpmtipjar.countries,
    categories: appState.rpmtipjar.categories,
    errors: appState.rpmtipjar.errors,
    user: appState.auth.user
  }
};

export default connect(mapStateToProps)(RPMTipJar);