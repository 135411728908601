import React from 'react';
import { connect } from 'react-redux';
import {
  unsetArticles,
  updateArticle,
  activateInactivateArticle,
  bulkToggleArticleActivation,
  getPublisherArticlesFiltered,
  switchMonetizeType,
} from '../actions/article';
import Widget from '../components/widget';
import { getPublisher } from '../actions/publisher';
import { Getter } from '@devexpress/dx-react-core';
import DatePicker from "react-datepicker";
import {
  EditingState,
  PagingState,
  CustomPaging,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  PagingPanel,
  TableColumnResizing,
  TableSelection,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import { numberWithCommas } from '../helpers/common';
import {
  defaultColumnWidthsArt as defaultColumnWidths,
  gridColumnsMappingsArt as gridColumnsMappings,
} from '../constants/columnDefs';
import {
  FormControl,
  Grid as MUIGrid,
  InputLabel,
  Select,
  TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
  Spinner,
} from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import { th } from 'date-fns/locale';

class Articles extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      showEditModal: false,
      rows: [],
      selected: [],
      selectedRows: [],
      columns: [],
      editableColumns: [],
      filters: [],
      defaultHiddenColumnNames: [],
      totalCount: 0,
      currentPage: 0,
      pageSize: 20,
      loading: false,
      filterAuthor: '',
      filterArticleURL: '',
      filterCategory: '',
      monetizeType: 'paywall',
      publishedDateFrom: null,
      publishedDateTo: null,
      showSpinner: true,
      filterArticleIdentifier: '',
    };
    this.onRowChanges = this.onRowChanges.bind(this);
    this.setFilterState = this.setFilterState.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  setPublishedDateFrom(date) {
    var utcDate = new Date(date.getTime() - new Date().getTimezoneOffset() * 60000);
    if (utcDate >= this.state.publishedDateTo) {
      this.setState({
        publishedDateFrom: utcDate,
        publishedDateTo: utcDate
      })
    } else {
      this.setState({ publishedDateFrom: utcDate })
    }
  }

  setPublishedDateTo(date) {
    var utcDate = new Date(date.getTime() - new Date().getTimezoneOffset() * 60000);
    this.setState({ publishedDateTo: utcDate })
  }

  setFilterState(e) {
    this.setState({ filters: e });
  }

  handleFieldUpdate(e: any, field: any) {
    this.setState({ [field]: e.target.value });
  }

  setCurrentPage(e) {
    this.setState({ currentPage: e, selectedRows: [], selected: [] });
    if (!this.state.loading) {
      this.setState({ loading: true, showSpinner: true });
      this.fetchDataAndPrepareGrid(e);
    }
    this.setState({ loading: false });
  }

  setSelection(e) {
    const { rows } = this.state;
    const selectedRows = rows.filter(row => e.includes(rows.indexOf(row) + 1))
    this.setState({ selectedRows, selected: e });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { currentPage, pageSize } = this.state;
    this.buildColumns();
    this.buildEditableColumns();
    this.getPublisherAndArticles(id, currentPage, pageSize);
  }

  componentWillReceiveProps(nextProps: any) {
    const { id } = this.props.match.params;
    const nextId = nextProps.match.params.id;
    if (id !== nextId) {
      this.setState({
        selected: [],
        selectedRows: [],
        totalCount: 0,
        currentPage: 0,
        pageSize: 20,
        loading: false,
        filterAuthor: '',
        filterArticleURL: '',
        filterCategory: '',
        publishedDateFrom: null,
        publishedDateTo: null,
        showSpinner: true,
        filterArticleIdentifier: '',
      })

      const { currentPage, pageSize } = this.state;
      this.getPublisherAndArticles(nextId, currentPage, pageSize);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unsetArticles());
  }

  getPublisherAndArticles(id, currentPage, pageSize) {
    this.props.dispatch(getPublisher(id)).then(
      () => {
        this.setState({monetizeType: this.props.publisher.primaryMonetizeType});
        this.props
        .dispatch(getPublisherArticlesFiltered(id,
          this.state.currentPage + 1,
          this.state.pageSize,
          this.state.filterAuthor,
          this.state.filterArticleURL,
          this.state.filterCategory,
          this.state.publishedDateFrom,
          this.state.publishedDateTo,
          this.state.monetizeType,
          this.state.filterArticleIdentifier))
        .then(() => {
          this.buildRows();
        })
      }
    )
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({
        name: column.name,
        title: column.title
      })
      )
    this.setState({ columns: gridColumns });
  }

  buildRows() {
    const { list } = this.props;
    let hiddenColumnName: string[] = [];

    if (this.state.monetizeType === 'paywall') {
      hiddenColumnName = ['price1', 'price2', 'price3', 'tips', 'tipsRatio'];
    } else {
      hiddenColumnName = ['price', 'unlocks', 'unlockRatio', 'paid', 'paidRatio']
    }

    if (list.items) {
      let rows = list.items.map((article, i) => {
        const row = {
          index: i + 1,
          id: article.id,
          date: article.publishedDate,
          launchDate: article.articleStatistics.fewcentsDate,
          currency: article.currency,
          price: article.price,
          price1: article?.tipJarPriceOptions?.prices[0] ?? 0,
          price2: article?.tipJarPriceOptions?.prices[1] ?? 0,
          price3: article?.tipJarPriceOptions?.prices[2] ?? 0,
          author: article.author,
          url: 'https://' + article.originalUrl,
          visits: article.articleStatistics.visits,
          unlocks: article.articleStatistics.unlocks,
          unlockRatio: numberWithCommas(article.articleStatistics.unlockRatio * 100),
          paid: article.articleStatistics.paid,
          paidRatio: numberWithCommas(article.articleStatistics.paidRatio * 100),
          tips: article.articleStatistics.paid,
          tipsRatio: numberWithCommas(article.articleStatistics.paidRatio * 100),
          activation: article.active,
          category: article.category,
          monetizeType: article.monetizeType,
        };
        Object.keys(row).forEach(key => row[key] = row[key] || '');
        return row;
      });

      this.setState({ rows, totalCount: list.totalCount, showSpinner: false, defaultHiddenColumnNames: hiddenColumnName });
    }
  }

  buildEditableColumns() {
    const editableColumns = gridColumnsMappings.map(column => {
      return { columnName: column.name, editingEnabled: !!column.editable }
    });
    this.setState({ editableColumns });
  }

  getRowId(row) {
    return row.index;
  }

  handleActivateInactivate(index, id, action: boolean) {
    this.props
      .dispatch(activateInactivateArticle(id, action))
      .then(() => {
        const { rows } = this.state;
        const dupRows = [...rows];
        dupRows[index - 1] = { ...dupRows[index - 1], active: action.toString(), activation: action }
        this.setState({
          rows: dupRows
        })
      })
  }

  onRowChanges({ added, changed, deleted }: any) {
    let changedRows;

    if (changed) {
      const { rows } = this.state;
      const rowIndex: number = parseInt(Object.keys(changed)[0]);
      const changedValues = changed[rowIndex]

      // update on server
      this.props.dispatch(updateArticle({
        ...rows[rowIndex - 1],
        ...changedValues
      }, () => {
        // build updated grid data
        changedRows = [...rows]
        changedRows[rowIndex - 1] = {
          ...changedRows[rowIndex - 1],
          ...changedValues
        }

        // update component state for grid
        this.setState({ rows: changedRows });
      })
      );
    }
  }

  bulkToggleArticleActivation(e: any, toBeEnabled: boolean) {
    const { selectedRows } = this.state;
    const filteredArticleIds = selectedRows.map(article => article.id);
    this.props
      .dispatch(bulkToggleArticleActivation(filteredArticleIds, toBeEnabled))
      .then(() => {
        this.buildRows();
      });
  }

  switchToPaywall(e: any) {
    const { selectedRows } = this.state;
    const filteredArticleIds = selectedRows.map(article => article.id);
    this.props
      .dispatch(switchMonetizeType(filteredArticleIds, 'paywall'))
      .then(() => {
        this.buildRows();
      });
  }

  switchToTipjar(e: any) {
    const { selectedRows } = this.state;
    const filteredArticleIds = selectedRows.map(article => article.id);
    this.props
      .dispatch(switchMonetizeType(filteredArticleIds, 'tipjar'))
      .then(() => {
        this.buildRows();
      });
  }

  filterArticles(e: any) {
    if (!this.state.loading) {
      this.setState({ loading: true, currentPage: 0, showSpinner: true });
      this.fetchDataAndPrepareGrid(0);
    }
    this.setState({ loading: false });
  }

  fetchDataAndPrepareGrid(currentPage) {
    const { publisher } = this.props;

    this.props
      .dispatch(getPublisherArticlesFiltered(publisher.id,
        currentPage + 1,
        this.state.pageSize,
        this.state.filterAuthor,
        this.state.filterArticleURL,
        this.state.filterCategory,
        this.state.publishedDateFrom,
        this.state.publishedDateTo,
        this.state.monetizeType,
        this.state.filterArticleIdentifier))
      .then(() => {
        this.buildRows();
      })
  }

  render() {
    let { publisher } = this.props;
    const { rows,
      columns,
      editableColumns,
      selected,
      selectedRows,
      totalCount,
      currentPage,
      pageSize,
      publishedDateFrom,
      publishedDateTo,
      showSpinner } = this.state;

    const activationSelector = ({ value, onValueChange, ...props }) => {
      const { row } = props;
      const title = value ? 'inactivate' : 'activate';
      const icon = value ? 'fa-toggle-on' : 'fa-toggle-off';
      const color = value ? 'green' : 'gray';
      return (
        <a href="#" title={`${title}`} onClick={e => this.handleActivateInactivate(row.index, row.id, !value)}>
          <i className={`fa ${icon}`} style={{ color }} aria-hidden="true" />
        </a>
      )
    };

    const ActivationTypeProvider = props => (
      <DataTypeProvider formatterComponent={activationSelector}  {...props} />
    ); // for activation toggle ends


    // for article url
    const articleLinkSelector = ({ value, onValueChange, ...props }) => {
      return (
        <a target="_blank" rel="noopener noreferrer" href={value}>{value}</a>
      )
    };

    const ArticleLinkTypeProvider = props => (
      <DataTypeProvider formatterComponent={articleLinkSelector} {...props} />
    ); // for article url

    const selectedMonetizeTypeType = (filterName: string) => {
      return this.state?.monetizeType === filterName;
    }

    return (
      <Widget>
        <div className="table-responsive container-fluid" style={{ minHeight: "400px" }}>
          <h5>Content {publisher && <span>(Publisher: {publisher.name} | Currency: {publisher.bookCurrency.code})</span>} </h5>
          <MUIGrid container spacing={1}>
            <MUIGrid item md={12} xs={12} xl={2} lg={2}>
              <TextField
                fullWidth
                margin="normal"
                id="filterArticleURL"
                name="filterArticleURL"
                variant="outlined"
                label="Content URL"
                onChange={(e) => this.handleFieldUpdate(e, 'filterArticleURL')}
              />
            </MUIGrid>
            <MUIGrid item md={6} xs={12} xl={2} lg={2}>
              <TextField
                fullWidth
                margin="normal"
                id="filterArticleIdentifier"
                name="filterArticleIdentifier"
                variant="outlined"
                label="Article Identifier"
                onChange={(e) => this.handleFieldUpdate(e, 'filterArticleIdentifier')}
              />
            </MUIGrid>
            <MUIGrid item md={6} xs={12} xl={2} lg={2}>
              <TextField
                fullWidth
                margin="normal"
                id="filterAuthor"
                name="filterAuthor"
                variant="outlined"
                label="Author"
                onChange={(e) => this.handleFieldUpdate(e, 'filterAuthor')}
              />
            </MUIGrid>
            <MUIGrid item md={6} xs={12} xl={2} lg={2}>
              <TextField
                fullWidth
                margin="normal"
                id="filterCategory"
                name="filterCategory"
                variant="outlined"
                label="Category"
                onChange={(e) => this.handleFieldUpdate(e, 'filterCategory')}
              />
            </MUIGrid>
            <MUIGrid item md={6} xs={12} xl={2} lg={2}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Monetize Option</InputLabel>
                <Select
                  id="monetizeType"
                  name="monetizeType"
                  value={this.state.monetizeType}
                  onChange={(e) => this.handleFieldUpdate(e, 'monetizeType')}
                  label="Monetize Option"
                >
                  <MenuItem value={'paywall'}>Paywall</MenuItem>
                  <MenuItem value={'tipjar'}>Tipjar</MenuItem>
                </Select>
              </FormControl>
            </MUIGrid>
            <MUIGrid item md={6} xs={12} xl={2} lg={2}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Button
                    className="mr-1"
                    variant="contained"
                    style={{ backgroundColor: '#333', minWidth: '100px', height: '40px' }}
                    onClick={(e) => this.filterArticles(e)}
                    startIcon={<SearchIcon />}
                  >Search</Button>
              </FormControl>
            </MUIGrid>
            <MUIGrid md={12} xs={12} xl={12} lg={12} item style={{ margin: "auto", width: "100%", display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}>
              <Button
                className="mr-1"
                disabled={selectedRows.length === 0}
                onClick={(e) => this.bulkToggleArticleActivation(e, true)}
                variant="contained"
                style={{ backgroundColor: '#1ab394', minWidth: '200px', height: '40px'  }}
                startIcon={<CheckIcon />}
              >Activate {selectedRows.length > 0 ? selectedRows.length : ""} Selected</Button>
              <Button
                className="mr-1"
                variant="contained"
                disabled={selectedRows.length === 0}
                onClick={(e) => this.bulkToggleArticleActivation(e, false)}
                style={{ backgroundColor: '#eb3349', minWidth: '200px', height: '40px'  }}
                startIcon={<CloseIcon />}
              >Deactivate {selectedRows.length > 0 ? selectedRows.length : ""}  Selected</Button>
              {
                !selectedMonetizeTypeType('paywall')
                  ? <Button
                    className="mr-1"
                    disabled={selectedRows.length === 0}
                    onClick={(e) => this.switchToPaywall(e)}
                    variant="contained"
                    style={{ backgroundColor: '#1ab394', minWidth: '275px', height: '40px'  }}
                    startIcon={<CheckIcon />}
                  >Switch Selected {selectedRows.length > 0 ? selectedRows.length : ""} to Paywall</Button>
                  : <Button
                    className="mr-1"
                    disabled={selectedRows.length === 0}
                    onClick={(e) => this.switchToTipjar(e)}
                    variant="contained"
                    style={{ backgroundColor: '#1ab394', minWidth: '275px', height: '40px'  }}
                    startIcon={<CheckIcon />}
                  >Switch Selected {selectedRows.length > 0 ? selectedRows.length : ""} to Tipjar</Button>
              }
            </MUIGrid>
          </MUIGrid>
          <MUIGrid md={12} xs={12} xl={12} lg={12}>
            <br />
            {
              showSpinner &&
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spinner animation='grow'></Spinner>
                <span className="ml-4"> {"Loading..."}</span>
              </div>
            }
            {
              !showSpinner &&
              <Grid rows={rows} columns={columns} getRowId={this.getRowId}>
                <ActivationTypeProvider for={["activation"]} />
                <ArticleLinkTypeProvider for={["url"]} />
                <EditingState onCommitChanges={this.onRowChanges} columnExtensions={editableColumns} />
                {/* <SortingState defaultSorting={[{ columnName: 'date', direction: 'desc' }]} />
            <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} /> */}
                <PagingState
                  defaultCurrentPage={0}
                  currentPage={currentPage}
                  defaultPageSize={pageSize}
                  onCurrentPageChange={e => this.setCurrentPage(e)}
                  pageSize={pageSize}
                />
                <CustomPaging totalCount={totalCount} />
                <Table />

                <SelectionState
                  selection={selected}
                  onSelectionChange={e => this.setSelection(e)}
                />
                <IntegratedSelection />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow />
                <TableColumnVisibility defaultHiddenColumnNames={this.state.defaultHiddenColumnNames} />
                <TableSelection showSelectAll />
                <TableEditColumn showEditCommand />
                <TableEditRow />

                <Getter
                  name="tableColumns"
                  computed={({ tableColumns }) => {
                    const result = [
                      ...tableColumns.filter(c => c.type !== TableEditColumn.COLUMN_TYPE),
                      { key: 'editCommand', type: TableEditColumn.COLUMN_TYPE, width: 140 }
                    ];
                    return result;
                  }}
                />
                <PagingPanel />
              </Grid>
            }
          </MUIGrid>
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (appState: any) => {
  return {
    publisher: appState.publishers.selectedPublisher,
    list: appState.articles.list,
    errors: appState.articles.errors,
    user: appState.auth.user
  }
};

export default connect(mapStateToProps)(Articles);