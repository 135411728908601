import { ApiService } from './apiService';
import { setAuthToken, hasAuthToken, getAuthToken, removeAuthToken } from '../helpers';
import { getOrCreateDeviceIndentity } from '../helpers';

class Auth {
  isUserLoggedIn() {
    return hasAuthToken();
  }

  hasAuthToken() {
    return !!getAuthToken();
  }

  userNotLoggedIn() {
    return !this.isUserLoggedIn()
  }

  loginOrRegister(url: string, payload: any) {
    return ApiService
      .post(url, { ...payload })
      .then( response => {
        const { data: { user, accessToken }, success } = response.data;
        if(response.status === 200 && success && user && user.emailVerified ) {
          setAuthToken(accessToken);
          //Todo: need to remove this later
          localStorage.setItem('userEmail', user.email);
        }
        return response;
      })
  }

  // logOut(userDevice?: any) {
  //   return ApiService
  //     .put('/user/logout', { userDevice })
  //     .then( response => {
  //       const { message, success } = response.data;
  //       if(response.status === 200 && success) {
  //         return response;
  //       }
  //       throw new Error(message);
  //     })
  //     .then(() => {
  //       removeAuthToken();
  //       localStorage.removeItem("userEmail");
  //     })
  //     .catch(err => {
  //       removeAuthToken();
  //       localStorage.removeItem("userEmail");
  //       throw err;
  //     })
  // }

  logOut(email?: any) {
    const payload = {
      email: email,
      deviceKey: getOrCreateDeviceIndentity(),
    };
    return ApiService
      .put('/user/logout/device', { ...payload })
      .then( response => {
        const { message, success } = response.data;
        if(response.status === 200 && success) {
          return response;
        }
        throw new Error(message);
      })
      .then(() => {
        removeAuthToken();
      })
      .catch(err => {
        removeAuthToken();
        throw err;
      })
  }

  getUser() {
    return new Promise((resolve, reject) => {
      if(this.userNotLoggedIn()) {
        resolve();
      } else
      {
        // TODO: Need to fetch user based on auth-token
        const email = localStorage.getItem("userEmail");
        ApiService
          .get(`/user/${email}`)
          .then( response => {
            const { success } = response.data;
            if(response.status === 200 && success) {
              return resolve(response);
            }
            return resolve();
          })
          .catch((error: Error) => {
            return reject(error);
          })     
      }
    })
  }
}

export const AuthService =  new Auth();
