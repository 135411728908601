/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Button
} from '@material-ui/core'
import style from '../styles/modal.module.scss';

const SharedModal = (props) => {
  const {
    show,
    isPrimaryActionDestructive,
    modalTitle,
    onSaveClick,
    onCancelClick,
    saveBtnLabel,
    cancelBtnLabel,
    children
  } = props;

  return (
    <Dialog open={show} >
      <DialogTitle>
        <strong>{modalTitle}</strong>
        <span className="float-right" style={{ cursor:"pointer" }} onClick={onCancelClick}><i className="fas fa-times"></i></span>
        </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button classes={{ root: style.deleteButton, label: style.deleteButtonText }} onClick={onCancelClick}>{cancelBtnLabel}</Button>
        <Button style={{ backgroundColor: isPrimaryActionDestructive ? "#eb3349" : "#1ab394" }} onClick={onSaveClick}>{saveBtnLabel}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SharedModal;
