import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  list: [],
  inProcess: false,
  currencies: [],
  selectedPublisher: null
}

export default (state = defaultState, action: IAction) => {
  const payload = action.payload || {};
  switch (action.type) {
    case Action.SET_PUBLISHERS:
      return {
        ...state,
        list: [...payload.list]
      };
    case Action.SET_UNSET_PUBLISHER_ERRORS:
      return {
        ...state,
        errors: payload.errors
      }
    case Action.PUBLISHER_API_PROCESSING:
      return {
        ...state,
        inProcess: payload.inProcess
      }
    case Action.SET_CURRENCIES:
      return {
        ...state,
        currencies: payload.currencies
      }
    case Action.SET_SELECTED_PUBLISHER:
      return {
        ...state,
        selectedPublisher: payload.publisher,
        inProcess: payload.inProcess
      }
    default:
      return state;
  }
};