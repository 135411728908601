import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form } from 'reactstrap';
import Widget from '../components/widget';
import { resetAuth, onForgotPasswordSubmitForm } from '../actions/auth';
import ListErrors from '../components/listErrors';
import style from '../styles/login.module.scss';
import { TextField, FormGroup, Button } from '@material-ui/core';
import { WebsiteTitle } from '../config';

class ForgotPassword extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { email: '', errors: {} };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.redirectToResetPasswordPage = this.redirectToResetPasswordPage.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(resetAuth());
  }

  handleFieldUpdate(e: any) {
    this.setState({ email: e.target.value });
  }

  handleFormSubmit(e: any) {
    e.preventDefault();
    this.setState({ errors: {} });
    const { email } = this.state;
    const errors: any = {};
    if (!email) {
      errors['Require:'] = 'email can not be empty';
      return this.setState({ errors });
    }
    // redirect to reset password page after success resoponse
    this.props.dispatch(onForgotPasswordSubmitForm({ email }, this.redirectToResetPasswordPage));
  }

  redirectToResetPasswordPage() {
    this.props.history.push('/reset-password');
  }

  render() {
    const errors = Object.keys(this.state.errors).length ? this.state.errors : this.props.errors;

    return (
      <div className={style.root}>
        <Row>
          <Col>
            <Widget className={style.widget}>
              <h2 className={style.title}>{WebsiteTitle}</h2>
              <h4 style={{ textAlign: 'left' }}>
                <strong>Forgot Password</strong>
              </h4>
              <div style={{ textAlign: 'left', color: '#666' }}>
                <span>Enter email address used during registration</span>
              </div>
              <Form>
                {errors && !this.props.inProgress && <ListErrors errors={errors} />}
                <FormGroup>
                  <TextField
                    margin="normal"
                    id="email"
                    required
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    label="Email"
                    onBlur={this.handleFieldUpdate}
                  />
                </FormGroup>
                <FormGroup>
                  <Button
                    type="submit"
                    style={{ marginTop: '10px', backgroundColor: '#1ab394' }}
                    variant="contained"
                    onClick={this.handleFormSubmit}
                    disabled={this.props.inProgress}
                  >
                    {this.props.inProgress ? 'Sending...' : 'Send'}
                  </Button>
                </FormGroup>
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Link to="/login" className="fs-sm" style={{ color: '#21ce99' }}>
                    Existing User
                  </Link>
                  <Link to="/register" className="fs-sm" style={{ color: '#21ce99' }}>
                    New User
                  </Link>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    ...appState.auth
  };
};

export default connect(mapStateToProps)(ForgotPassword);
