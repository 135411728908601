import React from 'react';
import { connect } from 'react-redux';
import { 
  getPublisherFbids,
  resetActivities, 
  reverseBid,
  getPublisherBids } from '../actions/activity';
import {
  PagingState,
  CustomPaging,
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Badge,
  Spinner,
  Button
} from 'reactstrap';
import Widget from '../components/widget';
import { getPublisher } from '../actions/publisher';
import { defaultColumnWidthsBids as defaultColumnWidths, gridColumnsMappingsBids as gridColumnsMappings, pageSizesBids as pageSizes, defaultPageSizeBids as defaultPageSize, integratedSortingColumnExtensions } from '../constants/columnDefs';
import { filterValue, filterDate, numberWithCommas } from '../helpers/common';

const statusSelector = ({ value, onValueChange, ...props }) => {
  const unlocked = props.row.status
  const unlockedObj = {
    success: {
      text: "Unlocked",
      color: "success"
    },
    danger: {
      text: "Locked",
      color: "danger"
    }
  }
  return (
    <Badge color={unlocked ? unlockedObj.success.color : unlockedObj.danger.color} className="text-white">
      {unlocked ? unlockedObj.success.text : unlockedObj.danger.text}
    </Badge>
  )
}

const StatusTypeProvider = props => (
  <DataTypeProvider formatterComponent={statusSelector} {...props} />
);

class Activities extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = ({
      rows: [],
      columns: [],
      searchValue: "",
      totalCount: 0,
      currentPage: 0,
      pageSize: 50,
      loading : false,
      showSpinner : true,
    });
    this.handleReverseBid = this.handleReverseBid.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);  
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.buildColumns();
    this.props.dispatch(getPublisher(id));
    this.getPublisherBidsFirstPage(id);
  }

  componentWillReceiveProps(nextProps: any) {
    const { id } = this.props.match.params;
    const nextId = nextProps.match.params.id;
    if(id !== nextId) {
      this.setState({ showSpinner : true })
      this.props.dispatch(getPublisher(nextId));
      this.getPublisherBidsFirstPage(nextId);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetActivities());
  }

  buildColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({
        name: column.name,
        title: column.title
      })
      )
    this.setState({ columns: gridColumns });
  }

  setCurrentPage(e) {
    this.setState({ currentPage: e });
    const { publisher } = this.props;
    this.fetchDataAndBindGrid(publisher.id, e, this.state.pageSize);
  }

  getPublisherBidsFirstPage(id: any) {
    this.fetchDataAndBindGrid(id, 0, this.state.pageSize);
  }

  fetchDataAndBindGrid(publisherId, currentPage, pageSize) {
    if (!this.state.loading) {
      this.setState({ loading: true });
    this.props
      .dispatch(getPublisherBids(publisherId, currentPage, pageSize))
      .then(() => {
        const { publisher, publisherHistory }  = this.props;
        this.buildRows(publisherHistory.payload.items, publisherHistory.payload.totalCount);
      }) 
    }
    this.setState({ loading: false });
  }

  getPublisherFbidsAndLedgers(id: any) {
    this.props.dispatch(getPublisher(id)).then(() => {
      this.props.dispatch(getPublisherFbids(id)
      );
    })
  }
  
  buildRows(bids, totalCount) {
    let rows = bids.map((fbid, i) => {
      return {
        id: fbid.id,
        date: fbid.createdTime ? filterDate(fbid.createdTime) : '',
        customer: fbid.email === 'anonymous@fewcents.co' ? 'N/A' : fbid.email,
        url: fbid.url,
        country: fbid.bidCountry,
        price: fbid.bidPublisherPrice ? (fbid.bidPublisherCurrency + ' ' + numberWithCommas(fbid.bidPublisherPrice)) : "",
        bidMode: filterValue(fbid.bidMode),
        unlockMode: filterValue(fbid.unlockMode),
        earning: fbid.earning ? (fbid.bidPublisherCurrency + ' ' + numberWithCommas(fbid.earning)) : "",
        paymentMode: fbid.paymentMode,
        status: fbid.unlocked,
        reversal: fbid.transactionId,
        isReversalAllowed: fbid.isReversalAllowed,
      };
    });
    this.setState({ rows, showSpinner : false, totalCount });
  }

  handleReverseBid(publisher, transactionId, action: boolean) {
    this.props
      .dispatch(reverseBid(publisher, transactionId))
      .then(() => {
        const { fbids } = this.props;
        this.buildRows(fbids, this.state.totalCount);
      }) 
  }

  render() {
    const { rows, columns, searchValue, totalCount, currentPage, pageSize, loading, showSpinner } = this.state;
    const { publisher, fbids } = this.props;
    const showFilterAndDownload = fbids && fbids.length > 0;
    let reversalAdjustedColumns = publisher && !publisher.isRefundAllowed
      ? columns.filter(y => y.name !== 'reversal')
      : columns;

    let gridColumns = publisher && !publisher.showCustomerEmail
      ? reversalAdjustedColumns.filter(y => y.name !== 'customer')
      : reversalAdjustedColumns;
    


    // for article url
    const articleLinkSelector = ({ value, onValueChange, ...props }) => {
      return (
        <a target="_blank" rel="noopener noreferrer" href={value}>{value}</a>
      )
    };

    const ArticleLinkTypeProvider = props => (
      <DataTypeProvider formatterComponent={articleLinkSelector} {...props} />
    ); // for article urlfor article url

    const reversionSelector = ({ value, onValueChange, ...props }) => {
      const { row } = props;
      const title =  'reverse';
      const showReversal = props.row.isReversalAllowed;

      return(
        showReversal &&
          <button
            className="btn btn-success btn-sm pull-center"
            title= {title}
            onClick={e => { this.handleReverseBid(row.publisherId, row.reversal, !value)}}
          >
          <span>
              <i className="fa"></i>Reverse
          </span>
          </button>
      )
    };
    
    const ReversionTypeProvider = props => (
      <DataTypeProvider formatterComponent={reversionSelector}  {...props} />
    );

    return (
      <Widget>
        <div className="table-responsive container-fluid" style={{ minHeight: "200px" }}>
          <h5>History { publisher && <span>(Publisher: {publisher.name} | Currency: {publisher.bookCurrency.code})</span>}</h5>
          {showSpinner &&  
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spinner animation='grow'></Spinner>
              <span className="ml-4"> {"Loading..."}</span>
            </div>
          }
          {!showSpinner &&  
          <Grid
          rows={rows}
          columns={gridColumns}
          >
          <ArticleLinkTypeProvider for={["url"]} />
          <ReversionTypeProvider for={["reversal"]} />
          {
            <StatusTypeProvider for={["status"]} />
          }
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={e => this.setCurrentPage(e)}
            pageSize={pageSize}
         />
          <CustomPaging
            totalCount={totalCount}
          />
          <Table />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow />
          <PagingPanel/>
          {/* <Toolbar /> */}
        </Grid>
        }
        </div>
      </Widget>
    )
  }
}

const mapStateToProps = (appState: any) => {
  return {
    publisher: appState.publishers.selectedPublisher,
    fbids: appState.activities.fbids,
    publisherHistory: appState.activities.publisherHistory,
    errors: appState.activities.errors,
    user: appState.auth.user
  }
};

export default connect(mapStateToProps)(Activities);