import { setCookie, deleteCookie, getCookie } from "./common";

// Auth Token
export function setAuthToken(token: string) {
  setCookie("fcpAuthToken", token, 3600000);
}

export function getAuthToken() {
  return getCookie("fcpAuthToken");
}

export function hasAuthToken() {
  return !!getAuthToken();
}

export function removeAuthToken() {
  deleteCookie("fcpAuthToken");
}

