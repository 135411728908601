import React from 'react';
import { connect } from 'react-redux';
import { getPublisherWallets } from '../actions/wallet';
import { getPublisher } from '../actions/publisher';
import { 
  getPublisherLedgers,
  resetActivities,
 } from '../actions/activity';
import Widget from '../components/widget';
import {
  Row, 
  Col,
} from 'reactstrap';
import {
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  TableColumnResizing
} from '@devexpress/dx-react-grid-bootstrap4';
import { numberWithCommas } from '../helpers/common';
import { defaultColumnWidthsBal as defaultColumnWidths, gridColumnsMappingsBal as gridColumnsMappings, pageSizesBal as pageSizes, defaultPageSizeBal as defaultPageSize, integratedSortingColumnExtensions } from '../constants/columnDefs';


class Wallets extends React.Component<any, any> {
  
  constructor(props) {
    super(props);
    this.state = ({
      walletRows: [],
      ledgerRows: [],
      ledgerColumns: [],
      errorMessage: "",
      successMessage: "",
    });
  }
 
  componentDidMount() {
    const { id } = this.props.match.params;
    this.buildLedgerColumns();
    this.getPublisherAndWallets(id)
    this.getPublisherLedgers(id)
  }

  componentWillReceiveProps(nextProps: any) {
    const { id } = this.props.match.params;
    const nextId = nextProps.match.params.id;
    if(id !== nextId) {
      this.getPublisherAndWallets(nextId);
      this.getPublisherLedgers(nextId)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetActivities());
  }

  getPublisherAndWallets(id) {
    this.props.dispatch(getPublisher(id));
    this.props
      .dispatch(getPublisherWallets(id))
      .then(() => {
        this.buildWalletRows();
      })    
  }

  getPublisherLedgers(id: any) {
    this.props
      .dispatch(getPublisherLedgers(id))
      .then(() => {
        this.buildLedgerRows();
      })
  }

  getFriendlyTransactionType(transactionType) {
    switch(transactionType) { 
      case 'ftpincome': { 
         return 'Content Unlock'; 
         break; 
      } 
      case 'ftprefund': { 
        return 'Content Refund'; 
        break; 
      }
      case 'ptransaction': { 
        return 'Payout'; 
        break; 
      } 
      default: { 
          return transactionType; 
         break; 
      } 
    } 
  }

  buildLedgerColumns() {
    const gridColumns = gridColumnsMappings
      .map(column => ({
        name: column.name,
        title: column.title
      })
      )
    this.setState({ ledgerColumns: gridColumns });
  }

  buildWalletRows() {
    const { list } = this.props;
    let rows = list.map((wallet, i) => {
      return {
        index: i+1,
        // balance: wallet.balance ? numberWithCommasAndCurrency(wallet.balance, wallet.currency.code) : numberWithCommasAndCurrency(0, wallet.currency.code),
        balance: wallet.currency.code + ' ' + (wallet.balance ? numberWithCommas(wallet.balance ) : 0),
      };
    });
    this.setState({ walletRows : rows });
  }

  buildLedgerRows() {
    const { ledgers } = this.props;
    let rows = ledgers.map((ledger, i) => {
      return {
        index: i + 1,
        date: ledger.transactionTime,
        type: this.getFriendlyTransactionType(ledger.transactionType),
        paymenttype: ledger.paymentType,
        amount: ledger.amount ? ledger.currency + ' ' + numberWithCommas(ledger.amount) : "",
        balance: ledger.runningBalance ? ledger.currency + ' ' + numberWithCommas(ledger.runningBalance) : "",
      };
    });
    this.setState({ ledgerRows: rows });
  }

  render() {
    const { publisher } = this.props;
    const { walletRows, ledgerRows, ledgerColumns } = this.state;
    const balance = walletRows && walletRows.length > 0 && walletRows[0].balance;
    return(
      <>
        <Widget>
          <div className="table-responsive container-fluid">
            <h5>Current Balance { publisher && <span>(Publisher: {publisher.name}) : {balance}</span>} </h5>
            <h5 style={{ marginBottom: "0px", color: "#21ce99"}}>Monthly Statement</h5>
            <Grid
            rows={ledgerRows}
            columns={ledgerColumns}
            >
              <IntegratedFiltering />
              <PagingState
                defaultCurrentPage={0}
                defaultPageSize={defaultPageSize}
              />
              <IntegratedPaging />
              <Table />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow />
              <PagingPanel
                pageSizes={pageSizes}
              />
              {/* <Toolbar /> */}
            </Grid>
          </div>
        </Widget>
      </>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    publisher: appState.publishers.selectedPublisher,
    list: appState.wallets.list,
    errors: appState.wallets.errors,
    user: appState.auth.user,
    ledgers: appState.activities.ledgers
  }
};

export default connect(mapStateToProps)(Wallets);