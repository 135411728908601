import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import combinedReducers from './reducers';

const middlewares = [thunkMiddleware];
const middleWareEnhancer = applyMiddleware(...middlewares);
const store = createStore(combinedReducers, composeWithDevTools(middleWareEnhancer));

// const getMiddleware = () => {
//   // process.env.NODE_ENV === 'production'
//   if (false) {
//     return applyMiddleware(promiseMiddleware, localStorageMiddleware);
//   } else {
//     // Enable additional logging in non-production environments.
//     return applyMiddleware(promiseMiddleware, localStorageMiddleware, createLogger())
//   }
// }
// const store = createStore(reducer, composeWithDevTools(getMiddleware()))

export default store;