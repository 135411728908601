import React from 'react';
import { Alert } from 'reactstrap';

interface IListErrorsProps {
  errors: { [k: string]: string };
}
interface IListErrorsState {}

class ListErrors extends React.Component<IListErrorsProps, IListErrorsState> {
  render() {
    const errors = this.props.errors;
    if (errors) {
      return (
        <Alert size='sm' color='danger'>
          <ul style={{ textAlign: 'left', paddingLeft: '25px' }}>
            {Object.keys(errors).map(key => {
              return (
                <li key={key} style={{ color: 'red' }}>
                  {key} {errors[key]}
                </li>
              );
            })}
          </ul>
        </Alert>
      );
    } else {
      return null;
    }
  }
}

export default ListErrors;
