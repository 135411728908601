import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  fbids: [],
  ledgers: [], 
  publisherHistory: null,
}

export default (state = defaultState, action: IAction) => {
  const payload = action.payload || {};

  switch (action.type) {
    case Action.SET_LEDGERS:
      return {
        ...state,
        ledgers: payload.list
      }
    case Action.SET_FBIDS:
      return {
        ...state,
        fbids: payload.list
      }
    case Action.RESET_ACTIVITIES:
      return {
        ...defaultState
      }
    case Action.SET_HISTORY:
     return {
       ...state, 
       publisherHistory: payload 
     }
    default:
      return state;
  }
};