import React from 'react';
import { connect } from 'react-redux';
import { AuthService } from '../services';
import { logoutUser} from '../actions/auth';

class Logout extends React.Component<any, any> {
  componentWillMount() {
    const email = this.props.user.email;
    AuthService
      .logOut(email)
      .catch(err => {
        console.log("TODO: Needs to logout device on server");
      })
      .finally(() => {
        this.cleanup();
      })
  }

  cleanup() {
    this.props.dispatch(logoutUser());
    this.props.history.push('/login')
  }

  render() {
    return null
  }
}

const mapStateToProps = (appState: any) => {
  return { 
  ...appState.auth
}};

export default connect(mapStateToProps)(Logout);