import { IRegistration } from '../interfaces';

export function passwordValidator(password: string) {
  const errors = {};
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if(passwordRegex.test(password)) {
    return {};
  }
  errors["Password"] = "should be at least 8 characters long (At lease 1 alphabet, 1 numeric, 1 special character)";
  return errors;
}

export function registrationValidator(payload: IRegistration) {
  const { email, firstName, lastName, password, passwordConfirmation } = payload;
  let errors: any = {};
  const requiredText = 'is required';

  if(!email) {
    errors["Email"] = requiredText;
  }
  if(!firstName) {
    errors["First Name"] = requiredText;
  }
  if(!lastName) {
    errors["Last Name"] = requiredText;
  }
  if(!password) {
    errors["Password"] = requiredText;
  }
  if(!passwordConfirmation) {
    errors["Password Confirmation"] = requiredText;     
  }
  if(password) {
    const passwordError = passwordValidator(password);
    errors = { ...errors, ...passwordError }
  }
  if((password && passwordConfirmation) && password !== passwordConfirmation) {
    errors["Password confirmation"] = "did not match";
  }

  return Object.keys(errors).length > 0 ? errors : null;
}