import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from './alert';
import * as CommonMessages from '../constants/common';

export const setPublisherAggregatedStatsTipJar = (payload: any) => {
  return {
    type: ActionTypes.SET_AGGREGATED_STATS_TIPJAR,
    payload: {
      payload
    }
  }
}

export const setStatsCountriesTipJar = (list: any) => {
  return {
    type: ActionTypes.SET_STATS_TIPJAR_COUNTRIES,
    payload: {
      list
    }
  }
}

export const setStatsCategoriesTipJar = (list: any) => {
  return {
    type: ActionTypes.SET_STATS_TIPJAR_CATEGORIES,
    payload: {
      list
    }
  }
}

export const unsetStatiscticsTipJar = () => {
  return {
    type: ActionTypes.UNSET_STATISTICS_TIPJAR,
  }
}

export const setErrors = (errors: any) => {
  return {
    type: ActionTypes.SET_UNSET_RPM_TIPJAR_ERRORS,
    payload: {
      errors
    }
  }
}

export function getPublisherAggregatedStatsTipJar(payload) {
  payload.country = payload.country === "ALL" ? null : payload.country
  payload.category = payload.category === "ALL" ? null : payload.category
  return (dispatch: any, getState: any) => {
    return ApiService.post(`/publisher/accountingStatsTipJar`, payload)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            dispatch(setPublisherAggregatedStatsTipJar(body.data));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisherStatsCountriesTipJar(id) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${id}/distinctBidCountries`)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            const {distinctCountries} = body.data || [];
            dispatch(setStatsCountriesTipJar(distinctCountries));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function getPublisherStatsCategoriesTipJar(id) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${id}/articleCategories`)
      .then(response => {
        const { data: body, data: { success }, status} = response;
        if(status === 200) {
          if(body && success) {
            const {articleCategories} = body.data || [];
            dispatch(setStatsCategoriesTipJar(articleCategories));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}
