import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  list: []
}

export default (state = defaultState, action: IAction) => {
  const payload = action.payload || {};
  switch (action.type) {
    case Action.SET_WALLETS:
      return {
        ...state,
        list: [...payload.list]
      };
    case Action.SET_UNSET_WALLET_ERRORS:
      return {
        ...state,
        errors: payload.errors
      }
    default:
      return state;
  }
};