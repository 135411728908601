import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from './alert';
import * as CommonMessages from '../constants/common';
import { showSuccessMessage, showFailureMessage } from './toast';

export const setPublisherArticleCategories = (list: any[]) => {
  return {
    type: ActionTypes.SET_ARTICLE_CATEGORIES,
    payload: {
      list
    }
  }
}

export const unsetArticleCategories = () => {
  return {
    type: ActionTypes.UNSET_ARTICLE_CATEGORIES,
  }
}

export function getPublisherArticleCategories(id) {
  return (dispatch: any, getState: any) => {
    return ApiService.get(`/publisher/${id}/articleCategories`)
      .then(response => {
        const { data: body, data: { success }, status } = response;
        if (status === 200) {
          if (body && success) {
            const { articleCategories } = body.data || [];
            dispatch(setPublisherArticleCategories(articleCategories));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updateArticleCategoryPrice(payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    const publisherId = payload.publisherId;
    const id = payload.id;

    const requestPayload = {
      paywallPrice: payload.price,
      tipJarPrice: [
        payload?.price1 ?? payload.tipJarPriceOptions?.prices[0] ?? 0,
        payload?.price2 ?? payload.tipJarPriceOptions?.prices[1] ?? 0,
        payload?.price3 ?? payload.tipJarPriceOptions?.prices[2] ?? 0
      ]
    };

    return ApiService.put(`/publisher/${publisherId}/articleCategory/${id}/updatePriceSetting`, requestPayload)
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage('Updated Successfully')
          const { articlecategories: { list } } = getState();
          for (let i = 0; i < list.length; i++) {
            const category = list[i];
            if (category.id === data.id) {
              list[i] = data;
              dispatch(setPublisherArticleCategories([...list]));
              break;
            }
          }

          if (callback) callback();
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function toggleActiveStateForCategory(categoryId: number, publisherId: number, activeState: boolean) {
  return (dispatch: any, getState: any) => {
    return ApiService.put(`/publisher/${publisherId}/articleCategory/${categoryId}/active/${activeState}`, {})
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage('Updated Successfully')
          const { articlecategories: { list } } = getState();
          for (let i = 0; i < list.length; i++) {
            const category = list[i];
            if (category.id === data.id) {
              list[i] = data;
              dispatch(setPublisherArticleCategories([...list]));
              break;
            }
          }
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function switchMonetizeType(categoryId: number, publisherId: number, monetizeType: string) {
    return (dispatch: any, getState: any) => {
      return ApiService.put(`/publisher/${publisherId}/category/${categoryId}/switchMonetizeType/${monetizeType}`, {})
        .then(response => {
          const { success, data } = response.data;
          if (response.status === 200 && response.data && success) {
            showSuccessMessage('Updated Successfully')
            const { articlecategories: { list } } = getState();
            for (let i = 0; i < list.length; i++) {
              const category = list[i];
              if (category.id === data.id) {
                list[i] = data;
                dispatch(setPublisherArticleCategories([...list]));
                break;
              }
            }
          } else {
            const message = response.data.message || CommonMessages.COMMON_FAILURE;
            showFailureMessage(message);
          }
        })
        .catch(error => {
          dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
        })
  }
}
