import React from 'react';
import { connect } from 'react-redux';
import {
	getPublisher,
	updatePublisherBrandingAndContact,
	updatePublisherPaywallSettings,
	updatePublisherPaywallCustomization,
	updatePublisherArticlePrice
}
	from '../actions/publisher';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	Button,
	TextField,
	IconButton,
	FormGroup,
	ButtonGroup,
	withStyles,
	createStyles,
	Theme,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@material-ui/core';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import style from '../styles/publisherDetails.module.scss';

import _ from 'lodash';

import { Multiselect } from 'multiselect-react-dropdown';
import { SDKUrl } from '../config';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import Widget from '../components/widget';

const faFacebookIcon = faFacebookSquare as IconProp;

interface Styles extends Partial<Record<SwitchClassKey, string>> {
	focusVisible?: string;
}

interface Props extends SwitchProps {
	classes: Styles;
}

interface CountryType {
	code: string;
	label: string;
}

const countries: CountryType[] = [
	{ code: 'ALL', label: 'All' },
	{ code: 'AD', label: 'Andorra' },
	{ code: 'AE', label: 'United Arab Emirates' },
	{ code: 'AT', label: 'Austria' },
	{ code: 'AU', label: 'Australia' },
	{ code: 'BD', label: 'Bangladesh' },
	{ code: 'BE', label: 'Belgium' },
	{ code: 'BN', label: 'Brunei Darussalam' },
	{ code: 'BR', label: 'Brazil' },
	{ code: 'CA', label: 'Canada' },
	{ code: 'CH', label: 'Switzerland' },
	{ code: 'CN', label: 'China' },
	{ code: 'CO', label: 'Colombia' },
	{ code: 'CY', label: 'Cyprus' },
	{ code: 'CZ', label: 'Czechia' },
	{ code: 'DE', label: 'Germany' },
	{ code: 'DK', label: 'Denmark' },
	{ code: 'EE', label: 'Estonia' },
	{ code: 'EG', label: 'Egypt' },
	{ code: 'ES', label: 'Spain' },
	{ code: 'FI', label: 'Finland' },
	{ code: 'FJ', label: 'Fiji' },
	{ code: 'FR', label: 'France' },
	{ code: 'GB', label: 'United Kingdom' },
	{ code: 'GR', label: 'Greece' },
	{ code: 'HK', label: 'Hong Kong' },
	{ code: 'HU', label: 'Hungary' },
	{ code: 'ID', label: 'Indonesia' },
	{ code: 'IE', label: 'Ireland' },
	{ code: 'IL', label: 'Israel' },
	{ code: 'IN', label: 'India' },
	{ code: 'IQ', label: 'Iraq' },
	{ code: 'IS', label: 'Iceland' },
	{ code: 'IT', label: 'Italy' },
	{ code: 'JP', label: 'Japan' },
	{ code: 'KR', label: 'Korea (the Republic of)' },
	{ code: 'KW', label: 'Kuwait' },
	{ code: 'LA', label: 'Laos' },
	{ code: 'LK', label: 'Sri Lanka' },
	{ code: 'LT', label: 'Lithuania' },
	{ code: 'LU', label: 'Luxembourg' },
	{ code: 'LV', label: 'Latvia' },
	{ code: 'MC', label: 'Monaco' },
	{ code: 'ME', label: 'Montenegro' },
	{ code: 'MM', label: 'Myanmar' },
	{ code: 'MO', label: 'Macao' },
	{ code: 'MT', label: 'Malta' },
	{ code: 'MX', label: 'Mexico' },
	{ code: 'MY', label: 'Malaysia' },
	{ code: 'NG', label: 'Nigeria' },
	{ code: 'NL', label: 'Netherlands' },
	{ code: 'NO', label: 'Norway' },
	{ code: 'NP', label: 'Nepal' },
	{ code: 'NZ', label: 'New Zealand' },
	{ code: 'PH', label: 'Philippines' },
	{ code: 'PK', label: 'Pakistan' },
	{ code: 'PL', label: 'Poland' },
	{ code: 'PT', label: 'Portugal' },
	{ code: 'QA', label: 'Qatar' },
	{ code: 'RU', label: 'Russian Federation' },
	{ code: 'SA', label: 'Saudi Arabia' },
	{ code: 'SC', label: 'Seychelles' },
	{ code: 'SE', label: 'Sweden' },
	{ code: 'SG', label: 'Singapore' },
	{ code: 'SI', label: 'Slovenia' },
	{ code: 'SK', label: 'Slovakia' },
	{ code: 'TH', label: 'Thailand' },
	{ code: 'TR', label: 'Turkey' },
	{ code: 'TW', label: 'Taiwan' },
	{ code: 'US', label: 'United States' },
	{ code: 'VN', label: 'Viet Nam' },
	{ code: 'ZA', label: 'South Africa' },
];


// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
	return typeof String.fromCodePoint !== 'undefined'
		? isoCode
			.toUpperCase()
			.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
		: isoCode;
}

const IOSSwitch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 42,
			height: 26,
			padding: 0,
			margin: theme.spacing(1),
		},
		switchBase: {
			padding: 1,
			'&$checked': {
				transform: 'translateX(16px)',
				color: theme.palette.common.white,
				'& + $track': {
					backgroundColor: '#52d869',
					opacity: 1,
					border: 'none',
				},
			},
			'&$focusVisible $thumb': {
				color: '#52d869',
				border: '6px solid #fff',
			},
		},
		thumb: {
			width: 24,
			height: 24,
		},
		track: {
			borderRadius: 26 / 2,
			border: `1px solid ${theme.palette.grey[400]}`,
			backgroundColor: theme.palette.grey[50],
			opacity: 1,
			transition: theme.transitions.create(['background-color', 'border']),
		},
		checked: {},
		focusVisible: {},
	}),
)(({ classes, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	);
});

class PublisherEditUpdate extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			errors: null,
			editing: false,
			selectedPublisher: props.selectedPublisher,
			btnSaveBrandingEnabled: false,
			btnSavePaywallSettingsEnabled: false,
			btnSavePaywallCustomizationEnabled: false,
			btnSaveArticlePriceEnabled: false,
			errorInName: false,
			errorInPhone: false,
			errorInNameMessage: "",
			errorInPhoneMessage: "",
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleArticlePaywallPrice = this.handleArticlePaywallPrice.bind(this)
		this.handleGeofencingEnabledChange = this.handleGeofencingEnabledChange.bind(this);
		this.handleAutoEnableArticleChange = this.handleAutoEnableArticleChange.bind(this);
		this.handlePaywallCustomizationChange = this.handlePaywallCustomizationChange.bind(this);

		this.updatePublisherBrandingHandler = this.updatePublisherBrandingHandler.bind(this);
		this.updatePublisherArticlePriceHandler = this.updatePublisherArticlePriceHandler.bind(this);
		this.updatePublisherPaywallSettingsHandler = this.updatePublisherPaywallSettingsHandler.bind(this);
		this.updatePublisherPaywallCustomizationHandler = this.updatePublisherPaywallCustomizationHandler.bind(this);

		this.onCountriesChange = this.onCountriesChange.bind(this);
		this.onCountrySelect = this.onCountrySelect.bind(this);
		this.onCountryRemove = this.onCountryRemove.bind(this);
	}

	onCountriesChange = (event, selectedCountries) => {
		let { selectedPublisher } = this.state;
		const selectedCountryCodes = selectedCountries.map(selectedCountry => selectedCountry.code);
		const newPaywallCountries = _.union(selectedPublisher.paywallCountries, selectedCountryCodes);

		this.setState({
			selectedPublisher: { ...selectedPublisher, paywallCountries: newPaywallCountries },
			btnSavePaywallSettingsEnabled: true,
		});
	}

	componentWillMount() {
		const { id } = this.props.match.params;
		this.props
			.dispatch(getPublisher(id))
			.then(() => {
				this.setState({ selectedPublisher: this.props.selectedPublisher })
			});
	}

	componentWillReceiveProps(nextProps: any) {
		const { id } = this.props.match.params;
		const nextId = nextProps.match.params.id;
		if (id !== nextId) {
			this.props.dispatch(getPublisher(nextId))
				.then(() => {
					this.setState({ selectedPublisher: this.props.selectedPublisher })
				});
		}
	}

	handleGeofencingEnabledChange(e: any) {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, [e.target.name]: e.target.checked },
			btnSavePaywallSettingsEnabled: true,
		});
	}

	handleAutoEnableArticleChange(e: any) {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, [e.target.name]: e.target.checked },
			btnSavePaywallSettingsEnabled: true,
		});
	}

	handleChange(e: any) {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, [e.target.name]: e.target.value },
			btnSaveBrandingEnabled: true
		});
	}

	handleArticlePaywallPrice(e: any) {
		let { selectedPublisher } = this.state;

		this.setState({
			selectedPublisher: { ...selectedPublisher, minPrice: e.target.value },
			btnSaveArticlePriceEnabled: true
		});
	}

	handleArticleTipjarPrice(e: any, index: number) {
		let { selectedPublisher } = this.state;
		let { tipJarPriceOptions } = selectedPublisher;

		if (!tipJarPriceOptions?.prices) {
			tipJarPriceOptions = { prices: [] }
		}

		tipJarPriceOptions.prices[index] = e.target.value;

		this.setState({
			selectedPublisher: { ...selectedPublisher, tipJarPriceOptions: tipJarPriceOptions },
			btnSaveArticlePriceEnabled: true
		});
	}

	handlePaywallCustomizationChange(e: any) {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, [e.target.name]: e.target.value },
			btnSavePaywallCustomizationEnabled: true
		});
	}

	reset() {
		this.setState({
			editing: false,
			selectedPublisher: null
		});
	}

	handleIncrement = () => {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, numFreeArticles: this.state.selectedPublisher.numFreeArticles + 1 },
			btnSavePaywallSettingsEnabled: true
		});
	};

	handleDecrement = () => {
		let { selectedPublisher } = this.state;
		this.setState({
			selectedPublisher: { ...selectedPublisher, numFreeArticles: this.state.selectedPublisher.numFreeArticles - 1 },
			btnSavePaywallSettingsEnabled: true
		});
	};

	updatePublisherBrandingHandler() {

		const allowedCharsInName = /^[a-zA-Z0-9\s]*$/;
		const allowedCharsInPhoneNumber = /^\+?[1-9]\d{1,14}$/;

		const { selectedPublisher: {
			financeEmail,
			financePhone,
			name,
			id,
			logoURL }
		} = this.state;

		let isNameValueIncorrect = false
		let nameIncorrectMessage = ""
		if (!(name || '').trim()) {
			isNameValueIncorrect = true
			nameIncorrectMessage = "Name is mandatory.";
		}

		if (!allowedCharsInName.test(name)) {
			isNameValueIncorrect = true
			nameIncorrectMessage = "Only Alpha Numeric chcacters are allowed.";
		}

		let isPhoneValueIncorrect = false
		let phoneIncorrectMessage = ""
		if (!(financePhone || '').trim()) {
			isPhoneValueIncorrect = true
			phoneIncorrectMessage = "Phone is mandatory.";
		}

		if (!allowedCharsInPhoneNumber.test(financePhone)) {
			isPhoneValueIncorrect = true
			phoneIncorrectMessage = "Phone format is incorrect.";
		}


		if (isNameValueIncorrect || isPhoneValueIncorrect) {
			this.setState({
				errorInName: isNameValueIncorrect,
				errorInNameMessage: nameIncorrectMessage,
				errorInPhone: isPhoneValueIncorrect,
				errorInPhoneMessage: phoneIncorrectMessage,
				btnSaveBrandingEnabled: false
			});
		} else {
			this.props.dispatch(updatePublisherBrandingAndContact(id, {
				financeEmail,
				financePhone,
				name,
				logoURL
			}, () => {
				this.props.dispatch(getPublisher(id));
			}));
		}
	}

	updatePublisherArticlePriceHandler() {
		const { id } = this.props.match.params;
		const { selectedPublisher } = this.state;

		const payload = {
			paywallPrice: selectedPublisher.minPrice,
			tipJarPrice: selectedPublisher.tipJarPriceOptions.prices
		}

		this.props.dispatch(updatePublisherArticlePrice(id, payload, () => {
			this.props.dispatch(getPublisher(id));
		}));
	}

	updatePublisherPaywallSettingsHandler() {
		const { selectedPublisher: {
			id,
			numFreeArticles,
			geoFencingEnabled,
			facebookAPIVersion,
			facebookLoginEnabled,
			autoEnableArticle,
			paywallCountries }
		} = this.state;

		this.props.dispatch(updatePublisherPaywallSettings(id, {
			numFreeArticles,
			isGeoFencingEnabled: geoFencingEnabled,
			countries: paywallCountries,
			facebookAPIVersion,
			facebookLoginEnabled,
			autoEnableArticle
		}, () => {
			this.props.dispatch(getPublisher(id));
		}));
	}

	updatePublisherPaywallCustomizationHandler() {
		const { selectedPublisher: {
			id,
			preLoginText,
			postLoginText }
		} = this.state;

		this.props.dispatch(updatePublisherPaywallCustomization(id, {
			preLoginText,
			postLoginText,
		}, () => {
			this.props.dispatch(getPublisher(id));
		}));
	}

	getSeletedCountries(publisher) {
		const paywallCountries = [] as CountryType[];
		if (publisher && publisher.paywallCountries) {
			countries.forEach(x => {
				const include = publisher.paywallCountries.includes(x.code);
				if (include) {
					paywallCountries.push(x);
				}
			});
		}
		return paywallCountries;
	}

	onCountrySelect(selectedList, selectedItem) {
		let { selectedPublisher } = this.state;
		if (selectedItem.code.toLowerCase() === "all") {
			const selectedCountryCodes = countries.map(selectedCountry => selectedCountry.code);

			this.setState({
				selectedPublisher: { ...selectedPublisher, paywallCountries: selectedCountryCodes },
				btnSavePaywallSettingsEnabled: true,
			});

		} else {
			const selectedCountryCodes = selectedList.map(selectedCountry => selectedCountry.code);

			this.setState({
				selectedPublisher: { ...selectedPublisher, paywallCountries: selectedCountryCodes },
				btnSavePaywallSettingsEnabled: true,
			});
		}
	}

	onCountryRemove(selectedList, removedItem) {
		let { selectedPublisher } = this.state;
		if (removedItem.code.toLowerCase() === "all") {

			this.setState({
				selectedPublisher: { ...selectedPublisher, paywallCountries: null },
				btnSavePaywallSettingsEnabled: true,
			});

		} else {
			const selectedCountryCodes = selectedList.map(selectedCountry => selectedCountry.code);

			this.setState({
				selectedPublisher: { ...selectedPublisher, paywallCountries: selectedCountryCodes },
				btnSavePaywallSettingsEnabled: true,
			});
		}
	}

	render() {
		const publisher = this.state.selectedPublisher || {};
		let paywallSnippet = `<script async src="{sdkul}/static/js/paywall.js"></script>`;
		paywallSnippet = paywallSnippet.replace("{sdkul}", SDKUrl);

		const paywallQueueSnippet = "<script>\n" +
			"	__q={q:[],l:[]},Paywall=function(){__q.q.push(arguments)},Paywall.on=function(){__q.l.push(arguments)};\n" +
			"</script>";

		let paywallConstructorSnippet = "<script>\n" +
			"	Paywall({\n" +
			"		accessKey: 'xxxxxxxxxxxxxx',\n" +
			"		contentSelector: 'blog_content',\n" +
			"		primaryColor: '#F40009',\n" +
			"		category: 'test_article',\n" +
			"		logoUrl: 'https://pngimg.com/uploads/apple_logo/apple_logo_PNG19670.png',\n" +
			"	})\n" +
			"</script>";

		const showCountrySelection = publisher.geoFencingEnabled;
		const selectedCountries = this.getSeletedCountries(publisher);
		return (
			<Widget>
				<div className="table-responsive container-fluid">
					<h5>Settings { publisher && publisher.bookCurrency && <span>(Publisher: {publisher.name} | Currency: {publisher.bookCurrency.code})</span>} </h5>
					<Grid
						container
						spacing={4}
					>
						<Grid
							item
							lg={4}
							md={6}
							xl={4}
							xs={12}
						>
							<Card>
								<form
									autoComplete="off"
									noValidate
								>
									<CardContent>
										<div>
											<h5 style={{ marginBottom: "0px", color: "#21ce99" }}>Branding and Contact Details</h5>
											<br></br>
										</div>
										<Divider />
										<br></br>
										<Grid
											item
											spacing={3}
										>
											<FormGroup>
												<TextField
													fullWidth
													label="Name"
													margin="dense"
													name="name"
													onChange={this.handleChange}
													required
													value={publisher && publisher.name ? publisher.name : ""}
													variant="outlined"
													error={this.state.errorInName}
													helperText={this.state.errorInNameMessage}
												/>
											</FormGroup>
											<FormGroup>
												<TextField
													fullWidth
													label="Email"
													margin="dense"
													name="financeEmail"
													onChange={this.handleChange}
													value={publisher && publisher.financeEmail ? publisher.financeEmail : ""}
													variant="outlined"
												/>
											</FormGroup>
											<FormGroup>
												<TextField
													fullWidth
													label="Phone"
													margin="dense"
													name="financePhone"
													onChange={this.handleChange}
													required
													value={publisher && publisher.financePhone ? publisher.financePhone : ""}
													variant="outlined"
													error={this.state.errorInPhone}
													helperText={this.state.errorInPhoneMessage}
												/>
											</FormGroup>
										</Grid>
									</CardContent>
									<Divider />
									<CardActions style={{ paddingRight: "15px", justifyContent: "flex-end", flexDirection: "row" }}>
										<Button
											color="primary"
											variant="contained"
											disabled={!this.state.btnSaveBrandingEnabled}
											id="btnSaveBranding"
											onClick={this.updatePublisherBrandingHandler}
										>
											Save
										</Button>
									</CardActions>
								</form>
							</Card>
						</Grid>
						<Grid
							item
							lg={4}
							md={6}
							xl={4}
							xs={12}
						>
							<Card>
								<form
									autoComplete="off"
									noValidate
								>
									<CardContent>
										<div>
											<h5 style={{ marginBottom: "0px", color: "#21ce99" }}>Content Settings</h5>
											<br></br>
										</div>
										<Divider />
										<br></br>
										<div>
											{publisher ?
												<>
													<h5>
														<i className="fas fa-cart-plus" style={{ marginRight: "5px" }}></i>Metering
													</h5>
													<p style={{ fontSize: "15px", marginBottom: "2px" }}>
														Number of content pieces you would like to give Few¢ents users free of cost
													</p>
													<ButtonGroup size="small" aria-label="small outlined button group">
														<Button classes={{ root: style.deleteButton, label: style.deleteButtonText }} onClick={this.handleDecrement} disabled={publisher.numFreeArticles <= 0}>-</Button>
														<Button classes={{ root: style.deleteButton, label: style.deleteButtonText }} disabled>{publisher.numFreeArticles}</Button>
														<Button classes={{ root: style.deleteButton, label: style.deleteButtonText }} onClick={this.handleIncrement}>+</Button>
													</ButtonGroup>
												</> : <></>
											}
										</div>

										<div style={{ marginTop: "17px" }}>
											<h5>
												<i className="fas fa-map-marked" style={{ marginRight: "5px" }}></i>Geo-Fencing
											</h5>
											<p style={{ fontSize: "15px", marginBottom: "2px" }}>
												Disable / Enable content in selected geographies
											</p>
											<FormControlLabel
												control={<IOSSwitch onChange={this.handleGeofencingEnabledChange} checked={publisher.geoFencingEnabled ? true : false} name="geoFencingEnabled" />}
												label={publisher.geoFencingEnabled ? "On" : "Off"}
											/>
											{
												showCountrySelection && <Multiselect
													options={countries}
													selectedValues={selectedCountries}
													onSelect={this.onCountrySelect} // Function will trigger on select event
													onRemove={this.onCountryRemove} // Function will trigger on remove event
													displayValue="label"
													showCheckbox={true}
												//style={ chips: { background: "red" }, searchBox: { border: "none", "border-bottom": "1px solid blue", "border-radius": "0px" } }
												/>
											}
										</div>
										<div style={{ marginTop: "17px" }}>
											<h5>
												<i className="fas fa-map-marked" style={{ marginRight: "5px" }}></i>Article
											</h5>
											<p style={{ fontSize: "15px", marginBottom: "2px" }}>
												Auto Enable New Content
											</p>
											<FormControlLabel
												control={<IOSSwitch onChange={this.handleAutoEnableArticleChange} checked={publisher.autoEnableArticle ? true : false} name="autoEnableArticle" />}
												label={publisher.autoEnableArticle ? "On" : "Off"}
											/>
										</div>
									</CardContent>
									<Divider />
									<br></br>
									<CardActions style={{ paddingRight: "15px", justifyContent: "flex-end", flexDirection: "row" }}>
										<Button
											color="primary"
											variant="contained"
											disabled={!this.state.btnSavePaywallSettingsEnabled}
											id="btnSavePaywallSettings"
											onClick={this.updatePublisherPaywallSettingsHandler}
										>
											Save
										</Button>
									</CardActions>
								</form>
							</Card>
						</Grid>
						<Grid
							item
							lg={4}
							md={6}
							xl={4}
							xs={12}
						>
							<Card>
								<form
									autoComplete="off"
									noValidate
								>
									<CardContent>
										<div>
											<h5 style={{ marginBottom: "0px", color: "#21ce99" }}>Price Settings</h5>
											<br></br>
										</div>
										<Divider />
										<br></br>
										<Grid
											item
											spacing={2}
										>
											<FormGroup>
												<span>Paywall Price</span>
												<TextField
													fullWidth
													label="Price"
													margin="dense"
													type="number"
													name="paywallPrice"
													onChange={this.handleArticlePaywallPrice}
													value={publisher && publisher.minPrice ? publisher.minPrice : ""}
													variant="outlined"
													error={this.state.errorInName}
													helperText={this.state.errorInNameMessage}
												/>
											</FormGroup>
											<FormGroup style={{ marginTop: "17px" }}>
												<span>Tipjar Price</span>
												<div>
													<TextField
														fullWidth
														label="Low"
														margin="dense"
														type="number"
														name="tipjarPrice1"
														onChange={(event) => this.handleArticleTipjarPrice(event, 0)}
														value={publisher && publisher.tipJarPriceOptions && publisher.tipJarPriceOptions.prices ? publisher.tipJarPriceOptions.prices[0] : ""}
														variant="outlined"
														error={this.state.errorInName}
														helperText={this.state.errorInNameMessage}
													/>
													<TextField
														fullWidth
														label="Medium"
														margin="dense"
														type="number"
														name="tipjarPrice2"
														onChange={(event) => this.handleArticleTipjarPrice(event, 1)}
														value={publisher && publisher.tipJarPriceOptions && publisher.tipJarPriceOptions.prices ? publisher.tipJarPriceOptions.prices[1] : ""}
														variant="outlined"
														error={this.state.errorInName}
														helperText={this.state.errorInNameMessage}
													/>
													<TextField
														fullWidth
														label="High"
														margin="dense"
														type="number"
														name="tipjarPrice3"
														onChange={(event) => this.handleArticleTipjarPrice(event, 2)}
														value={publisher && publisher.tipJarPriceOptions && publisher.tipJarPriceOptions.prices ? publisher.tipJarPriceOptions.prices[2] : ""}
														variant="outlined"
														error={this.state.errorInName}
														helperText={this.state.errorInNameMessage}
													/>
												</div>
											</FormGroup>
										</Grid>
									</CardContent>
									<Divider />
									<CardActions style={{ paddingRight: "15px", justifyContent: "flex-end", flexDirection: "row" }}>
										<Button
											color="primary"
											variant="contained"
											disabled={!this.state.btnSaveArticlePriceEnabled}
											id="btnSaveBranding"
											onClick={this.updatePublisherArticlePriceHandler}
										>
											Save
										</Button>
									</CardActions>
								</form>
							</Card>
						</Grid>
					</Grid >
				</div >
			</Widget>
		)
	}
}

const mapStateToProps = (appState: any) => {
	return {
		...appState.publishers,
		user: appState.auth.user
	}
};

export default connect(mapStateToProps)(PublisherEditUpdate);