import React from 'react';
import './styles/theme.scss';
import { browserHistory } from './history';
import Login from './containers/login';
import Register from './containers/registration';
import Logout from './containers/logout';
import ForgotPassword from './containers/forgotPassword';
import ResetPassword from './containers/resetPassword';
import requireAuth from './components/authenticate';
import AccountActivation from './containers/accountActivation';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import LayoutComponent from './components/layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import { hasAuthToken } from './helpers/auth';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome,
  faWallet,
  faList,
  faKey,
  faSignOutAlt,
  faAngleUp,
  faAngleDown,
  faBookReader,
  faPencilAlt,
  faCheck,
  faTimes,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

library.add(faHome,
  faWallet,
  faList,
  faKey,
  faSignOutAlt,
  faAngleUp,
  faAngleDown,
  faBookReader,
  faPencilAlt,
  faCheck,
  faTimes, 
  faPlus)

  const theme = createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          outline: "none",
          "&:focus": {
            outline: 'none',
          }
        },
        label: {
          color: 'white',
        },
        containedPrimary: {
          "&:focus": {
            outline: 'none',
          }
        },
      },
    },
    palette: {
      primary: {
        main: '#21ce99',
      },
      secondary: {
        main: '#f44336',
      },
      success: {
        main: '#1ab394',
      },
      error: {
        main: '#eb3349',
      }
    },
  });

export default class App extends React.Component<any, any> {
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
        <ToastContainer
          autoClose={7000}
          hideProgressBar
        />
        <Router history={browserHistory}>
          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
            <Route exact path="/">
              {hasAuthToken() ? <Redirect to="/app/profile" /> : <Redirect to="/login" />}
            </Route>
            <Route path="/app" component={requireAuth(LayoutComponent)} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route path="/account/:id/activation" exact component={AccountActivation} />
            <Route path="/*">
              {hasAuthToken() ? <Redirect to="/app/profile" /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </Router>
        </ThemeProvider>
      </div>
    );
  }
}
