import Action from '../constants/actionTypes';
import { IAction } from "../interfaces";

const defaultState = {
  user: null,
  isAuthenticated: false,
  inProgress: false,
  success: false,
}

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case Action.SET_USER:
    case Action.SET_UNSET_AUTH_ERRORS:
    case Action.AUTH_FIELD_UPDATE:
    case Action.API_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case Action.PROFILE_UPDATE:
      const user = state.user || {};
      return {
        ...state,
        user: {
          ...user,
          ...action.payload
        }
      }; 
    case Action.FACEBOOK_AUTH_INITIATED:
      return {
        ...state,
        ...action.payload
      };
    case Action.FACEBOOK_AUTH_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case Action.RESET_AUTH:
    case Action.LOGOUT:
      return {
        ...defaultState
      };
    default:
      return state;
  }
};
