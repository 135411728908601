import ActionTypes from '../constants/actionTypes';
import { ApiService } from '../services';
import * as AlertActions from '../actions/alert';
import * as CommonMessages from '../constants/common';
import { showSuccessMessage, showFailureMessage } from './toast';

export const setPublisherArticles = (list: any) => {
  return {
    type: ActionTypes.SET_ARTICLES,
    payload: {
      list
    }
  }
}

export const unsetArticles = () => {
  return {
    type: ActionTypes.UNSET_ARTICLES,
  }
}

export const setErrors = (errors: any) => {
  return {
    type: ActionTypes.SET_UNSET_ARTICLE_ERRORS,
    payload: {
      errors
    }
  }
}

export function getPublisherArticlesFiltered(id, page, size, author, url, category, publishedDateFrom, publishedDateTo, monetizeType, articleIdentifier) {
  const payload = {
    authorFilter: author,
    urlFilter: url,
    categoryFilter: category,
    publishedDateFrom,
    publishedDateTo,
    monetizeType,
    articleIdentifier
  }

  return (dispatch: any, getState: any) => {
    return ApiService.post(`/publisher/${id}/articles/${page}/${size}`, payload)
      .then(response => {
        const { data: body, data: { success }, status } = response;
        if (status === 200) {
          if (body && success) {
            dispatch(setPublisherArticles(body.data));
          }
        } else {
          dispatch(AlertActions.setAlert(CommonMessages.COMMON_FAILURE));
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function updateArticle(payload: any, callback: any) {
  return (dispatch: any, getState: any) => {
    const url = payload.url ? payload.url : payload.originalUrl;

    const articleEditRequest = {
      id: payload.id,
      price: payload.price,
      baseUrl: url.replace('https://', ''),
      tipJarPrice: [
        payload?.price1 ?? payload.tipJarPriceOptions?.prices[0] ?? 0,
        payload?.price2 ?? payload.tipJarPriceOptions?.prices[1] ?? 0,
        payload?.price3 ?? payload.tipJarPriceOptions?.prices[2] ?? 0
      ]
    };

    return ApiService.put(`/article/update`, articleEditRequest)
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage('Updated Successfully')
          const { articles: { list } } = getState();
          for (let i = 0; i < list.length; i++) {
            const article = list[i];
            if (article.id === data.id) {
              list[i].price = data.price;
              list[i].url = data.url;
              dispatch(setPublisherArticles([...list]));
              break;
            }
          }

          if (callback) callback();
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function activateInactivateArticle(id: string | number, activate: boolean, callback?: any) {
  return (dispatch: any, getState: any) => {
    const method = activate ? 'act' : 'deact';
    return ApiService.put(`/article/${method}/${id}`, {})
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage('Updated Successfully')
          const { articles: { list } } = getState();
          for (let i = 0; i < list.length; i++) {
            const article = list[i];
            if (article.id === data.id) {
              list[i] = data;
              dispatch(setPublisherArticles([...list]));
              break;
            }
          }

          if (callback) callback();
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function bulkToggleArticleActivation(ids: number[], activate: boolean) {
  return (dispatch: any, getState: any) => {
    const method = activate ? 'activateAll' : 'deActivateAll';
    const message = activate ? 'activated' : 'Deactivated';
    return ApiService.put(`/article/${method}`, ids)
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage(`Articles ${message} Successfully`)
          const { articles: { list } } = getState();
          for (let count = 0; count < data.length; count++) {
            let index = list.items.findIndex(y => y.id === data[count].id);
            let article = list.items[index];
            if (article) {
              article.active = data[count].active;
              list.items[index] = article;
            }
          }
          dispatch(setPublisherArticles(list));
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}

export function switchMonetizeType(ids: number[], monetizeType: string) {
  return (dispatch: any, getState: any) => {
    return ApiService.put(`/article/switchMonetizeType/${monetizeType}`, ids)
      .then(response => {
        const { success, data } = response.data;
        if (response.status === 200 && response.data && success) {
          showSuccessMessage(`Updated Successfully`)
          const { articles: { list } } = getState();
          const updatedList = { ...list, items: list.items.filter(x => !ids.includes(x.id)) };
          dispatch(setPublisherArticles(updatedList));
        } else {
          const message = response.data.message || CommonMessages.COMMON_FAILURE;
          showFailureMessage(message);
        }
      })
      .catch(error => {
        dispatch(AlertActions.setAlert(`${CommonMessages.COMMON_FAILURE} ${error.message}`))
      })
  }
}
