import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListErrors from '../components/listErrors';
import { resetAuth, onRegisterationSubmitForm } from '../actions/auth';
import Widget from '../components/widget';
import { Row, Col, Form } from 'reactstrap';
import { getErrors } from '../helpers';
import { registrationValidator } from '../validations/common';
import style from '../styles/login.module.scss';

import { TextField, FormGroup, Button } from '@material-ui/core';
import { WebsiteTitle } from '../config';

class Registration extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = { password: '', passwordConfirmation: '', firstName: '', lastName: '', email: '', errors: null };
    this.redirectToAccountVerification = this.redirectToAccountVerification.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(resetAuth());
  }

  handleFieldChange(event: any, key: string) {
    const { value } = event.target;
    this.setState({ [key]: value });
  }

  handleFormSubmit(event: any) {
    event.preventDefault();
    const { email, firstName, lastName, password } = this.state;

    if (this.isInvalidDetails()) {
      return false;
    }

    const payload = {
      email,
      name: `${firstName} ${lastName}`,
      password
    };
    this.props.dispatch(onRegisterationSubmitForm(payload, this.redirectToAccountVerification));
  }

  redirectToAccountVerification(id: any) {
    this.props.history.push(`/account/${id}/activation`);
  }

  isInvalidDetails() {
    const { email, firstName, lastName, password, passwordConfirmation } = this.state;
    const errors = registrationValidator({
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation
    });

    this.setState({ errors });
    return !!errors;
  }

  render() {
    const stateErrors = this.state.errors;
    const propsErrors = this.props.errors;
    const errors = getErrors(stateErrors, propsErrors);

    return (
      <div className={style.root}>
        <Row>
          <Col>
            <Widget className={style.widget}>
              <h2 className={style.title}>{WebsiteTitle}</h2>
              <div style={{ textAlign: 'left', color: '#666' }}>
                <span>Use your Email to Signup</span>
              </div>
              <Form>
                {errors && !this.props.inProgress && <ListErrors errors={errors} />}
                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    className="no-border"
                    id="firstName"
                    required
                    name="firstName"
                    label="First Name"
                    onBlur={(e) => this.handleFieldChange(e, 'firstName')}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    className="no-border"
                    id="lastName"
                    required
                    name="lastName"
                    label="Last Name"
                    onBlur={(e) => this.handleFieldChange(e, 'lastName')}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    className="no-border"
                    id="email"
                    type="email"
                    required
                    name="email"
                    label="Email Address"
                    onBlur={(e) => this.handleFieldChange(e, 'email')}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    className="no-border"
                    id="password"
                    type="password"
                    required
                    name="password"
                    label="Password"
                    onBlur={(e) => this.handleFieldChange(e, 'password')}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    className="no-border"
                    id="passwordConfirmation"
                    type="password"
                    required
                    name="passwordConfirmation"
                    label="Confirm password"
                    onBlur={(e) => this.handleFieldChange(e, 'passwordConfirmation')}
                  />
                </FormGroup>

                <div>
                  <p className="fs-sm text-muted">
                    By signing up you accept <br />
                    our{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#21ce99' }}
                      href="https://fewcents.co/client_terms.html"
                    >
                      {' '}
                      Terms{' '}
                    </a>
                    and{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#21ce99' }}
                      href="https://fewcents.co/privacy.html"
                    >
                      {' '}
                      Privacy Policy
                    </a>
                  </p>
                  <FormGroup>
                    <Button
                      type="submit"
                      style={{ marginTop: '10px', backgroundColor: '#1ab394' }}
                      variant="contained"
                      onClick={this.handleFormSubmit}
                      disabled={this.props.inProgress}
                    >
                      {this.props.inProgress ? 'Saving...' : 'Signup'}
                    </Button>
                  </FormGroup>
                </div>

                <div className="d-flex justify-content-between align-items-center mt-4">
                  <Link to="/login" className="fs-sm" style={{ color: '#21ce99' }}>
                    Existing User
                  </Link>
                  <Link to="/forgot-password" className="fs-sm" style={{ color: '#21ce99' }}>
                    Forgot Password?
                  </Link>
                </div>
              </Form>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (appState: any) => {
  return {
    ...appState.auth
  };
};

export default connect(mapStateToProps)(Registration);
