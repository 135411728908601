import { compareDate } from '../helpers/common';

export const integratedSortingColumnExtensions = [
    { columnName: 'date', compare: compareDate }
]


/** fBid columns */

export const defaultColumnWidthsBids = [
    { columnName: 'date', width: 140 },
    { columnName: 'customer', width: 120 },
    { columnName: 'url', width: 280 },
    { columnName: 'country', width: 100 },
    { columnName: 'price', width: 100 },
    { columnName: 'bidMode', width: 80 },
    { columnName: 'unlockMode', width: 100 },
    { columnName: 'earning', width: 100 },
    { columnName: 'paymentMode', width: 110 },
    { columnName: 'status', width: 100 },
    { columnName: 'reversal', width: 100 },
];

export const gridColumnsMappingsBids = [
    { name: 'date', title: 'Visit Date' },
    { name: 'customer', title: 'Customer' },
    { name: 'url', title: 'Content' },
    { name: 'country', title: 'Country' },
    { name: 'price', title: 'Price' },
    { name: 'bidMode', title: 'Mode' },
    { name: 'unlockMode', title: 'Unlock' },
    { name: 'earning', title: 'Earning' },
    { name: 'paymentMode', title: 'Payment' },
    { name: 'status', title: 'Status' },
    { name: 'reversal', title: 'Reverse' },
]

export const pageSizesBids = [10, 20, 30, 0]

export const defaultPageSizeBids = 20;

/** fBid columns */

/** ledger columns */
export const defaultColumnWidthsBal = [
    { columnName: 'index', width: 50 },
    { columnName: 'date', width: 200 },
    { columnName: 'type', width: 150 },
    { columnName: 'paymenttype', width: 150 },
    { columnName: 'amount', width: 200 },
    { columnName: 'balance', width: 200 }
];

export const gridColumnsMappingsBal = [
    { name: 'index', title: '#' },
    { name: 'date', title: 'Transaction Date' },
    { name: 'type', title: 'Type' },
    { name: 'paymenttype', title: 'Payment' },
    { name: 'amount', title: 'Amount' },
    { name: 'balance', title: 'Balance' }
]

export const pageSizesBal = [10, 20, 30, 0]

export const defaultPageSizeBal = 10;

/** ledger columns */

/** devices columns */
export const defaultColumnWidthsDev = [
    { columnName: 'index', width: 50 },
    { columnName: 'browser', width: 225 },
    { columnName: 'os', width: 150 },
    // { columnName: 'deviceKey', width: 200 },
    { columnName: 'isActive', width: 100 },
    { columnName: 'logout', width: 100 },
];

export const gridColumnsMappingsDev = [
    { name: 'index', title: '#' },
    { name: 'browser', title: 'Browser' },
    { name: 'os', title: 'OS' },
    // { name: 'deviceKey', title: 'DeviceKey' },
    { name: 'isActive', title: 'Status' },
    { name: 'logout', title: 'Logout' }
]

export const pageSizesDev = [5, 10, 15, 0];

export const defaultPageSizeDev = 5;

/** devices columns */


/** publisher columns */

export const defaultColumnWidthsPub = [
    { columnName: 'index', width: 50 },
    { columnName: 'domain', width: 150 },
    { columnName: 'name', width: 120 },
    { columnName: 'financePhone', width: 150 },
    { columnName: 'financeEmail', width: 150 },
    { columnName: 'bookCurrency', width: 100 },
    { columnName: 'minPrice', width: 100 },
    { columnName: 'numFreeArticles', width: 100 },
];

export const gridColumnsMappingsPub = [
    { name: 'index', title: '#' },
    { name: 'domain', title: 'Domain' },
    { name: 'name', title: 'Name', editable: true },
    { name: 'financePhone', title: 'Phone', editable: true },
    { name: 'financeEmail', title: 'Email', editable: true },
    { name: 'bookCurrency', title: 'Currency' },
    { name: 'minPrice', title: 'Min Article Price' },
    { name: 'numFreeArticles', title: 'Metering' }
]

export const pageSizesPub = [10, 20, 30, 0];

export const defaultPageSizePub = 10;

/** publisher columns */



/** article columns */

export const defaultColumnWidthsArt = [
    { columnName: 'launchDate', width: 150 },
    { columnName: 'date', width: 150 },
    { columnName: 'price', width: 100 },
    { columnName: 'price1', width: 100 },
    { columnName: 'price2', width: 100 },
    { columnName: 'price3', width: 100 },
    { columnName: 'author', width: 100 },
    { columnName: 'url', width: 250 },
    { columnName: 'category', width: 150 },
    { columnName: 'activation', width: 100 },
    { columnName: 'monetizeType', width: 100 },
    { columnName: 'visits', width: 100 },
    { columnName: 'unlocks', width: 100 },
    { columnName: 'unlockRatio', width: 120 },
    { columnName: 'paid', width: 100 },
    { columnName: 'paidRatio', width: 100 },
    { columnName: 'tips', width: 100 },
    { columnName: 'tipsRatio', width: 120 },
];

export const gridColumnsMappingsArt = [
    { name: 'launchDate', title: 'F¢-Launch Date' },
    { name: 'date', title: 'Published Date' },
    { name: 'price', title: 'Price', editable: true },
    { name: 'price1', title: 'Low', editable: true },
    { name: 'price2', title: 'Medium', editable: true },
    { name: 'price3', title: 'High', editable: true },
    { name: 'author', title: 'Author' },
    { name: 'url', title: 'Content URL', editable: true },
    { name: 'category', title: 'Category' },
    { name: 'visits', title: 'Visits' },
    { name: 'unlocks', title: 'Unlocks' },
    { name: 'unlockRatio', title: 'Unlock %' },
    { name: 'paid', title: 'Paid' },
    { name: 'paidRatio', title: 'Paid %' },
    { name: 'tips', title: 'Tips' },
    { name: 'tipsRatio', title: 'Tips %'  },
    { name: 'activation', title: 'Active' },
    { name: 'monetizeType', title: 'Monetize Option' },
]

/** article columns */

/** article category columns */

export const defaultColumnWidthsArtCat = [
    { columnName: 'index', width: 50 },
    { columnName: 'category', width: 150 },
    { columnName: 'price', width: 150 },
    { columnName: 'price1', width: 100},
    { columnName: 'price2', width: 100 },
    { columnName: 'price3', width: 100 },
    { columnName: 'activateAll', width: 150 },
    { columnName: 'deactivateAll', width: 175 },
    { columnName: 'monetizeType', width: 150 },
];

export const gridColumnsMappingsArtCat = [
    // { name: 'index', title: '#' },
    { name: 'category', title: 'Category' },
    { name: 'price', title: 'Paywall Price', editable: true },
    { name: 'price1', title: 'Low', editable: true },
    { name: 'price2', title: 'Medium', editable: true },
    { name: 'price3', title: 'High', editable: true },
    { name: 'monetizeType', title: 'Monetize Type' },
    { name: 'activateAll', title: 'Activate' },
    { name: 'deactivateAll', title: 'Deactivate' },
]

export const pageSizesArtCat = [10, 20, 30, 0];

export const defaultPageSizeArtCat = 10;

/** article category columns */

/** rpm columns */

export const defaultColumnWidthsRpm = [
    { columnName: 'articleDate', width: 150 },
    { columnName: 'visitDate', width: 120 },
    { columnName: 'article', width: 200 },
    { columnName: 'category', width: 150 },
    { columnName: 'visitCountry', width: 150 },
    { columnName: 'publisherPrice', width: 150 },
    { columnName: 'customerPrice', width: 150 },
    { columnName: 'visits', width: 100 },
    { columnName: 'loggedinVisits', width: 100 },
    { columnName: 'unlocks', width: 100 },
    { columnName: 'paid', width: 100 },
];

export const gridColumnsMappingsRpm = [
    { name: 'visitDate', title: 'Visit Date' },
    { name: 'articleDate', title: 'F¢ Launch Date' },
    { name: 'article', title: 'Article' },
    { name: 'category', title: 'Category' },
    { name: 'visitCountry', title: 'Visit Country' },
    { name: 'publisherPrice', title: 'Publisher Price' },
    { name: 'customerPrice', title: 'Customer Price' },
    { name: 'visits', title: 'Visits' },
    { name: 'loggedinVisits', title: 'Logged Visits' },
    { name: 'unlocks', title: 'Unlocks' },
    { name: 'paid', title: 'Paid' },
]

export const pageSizesRpm = [10, 20, 30, 0];

export const defaultPageSizeRpm = 20;

/** rpm columns */